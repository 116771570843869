import axiosInstance from "../config/axiosIntance";
import { ResponseUnlockLockService } from "../types";

type Params = {
  documentNumber?: string;
  serviceProvider: string;
  token: string;
};

export const getDocumentStatusService = async ({
  documentNumber,
  serviceProvider,
  token
}: Params): Promise<ResponseUnlockLockService> => {
  const responseFetched = await axiosInstance.get<ResponseUnlockLockService>(`/audits/blocklist/customers`, {
    params: {
      documentNumber,
      serviceProvider
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return responseFetched.data;
};
