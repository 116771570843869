import { OPTInOptionList } from "../config/constants";
import { OptionList, PurposeType, ServiceProvider } from "../types";

const deleteDuplicateValues = (list?: OptionList[]) => {
  return list?.filter((value, index, self) => index === self.findIndex((t) => t.label === value.label));
};

export const serviceProvidersOptionsList = (listServiceProvider?: ServiceProvider[]): OptionList[] => {
  const newFormatOptionsList = listServiceProvider?.map((item: ServiceProvider) => {
    return {
      label: item.serviceProvider,
      value: item.id
    };
  });

  return newFormatOptionsList ?? [];
};

export const countriesOptionsList = (listServiceProvider?: ServiceProvider[]): OptionList[] => {
  const newFormatOptionsList = listServiceProvider?.map((item: ServiceProvider) => {
    return {
      label: item.country,
      value: item.id
    };
  });

  return deleteDuplicateValues(newFormatOptionsList) ?? [];
};

export const businessOptionsList = (listServiceProvider?: ServiceProvider[]): OptionList[] => {
  const newFormatOptionsList = listServiceProvider?.map((item: ServiceProvider) => {
    return {
      label: item.business,
      value: item.id
    };
  });

  return deleteDuplicateValues(newFormatOptionsList) ?? [];
};

export const getListBusinessAliases = (listServiceProvider?: ServiceProvider[]): OptionList[] => {
  const newFormatOptionsList = listServiceProvider?.map((item: ServiceProvider) => {
    return {
      label: item.aliasBusiness,
      value: item.business
    };
  });

  return deleteDuplicateValues(newFormatOptionsList) ?? [];
};

export const purposeOptionsList = (purposeList?: PurposeType[]): OptionList[] => {
  const newList = purposeList?.map((item: PurposeType) => {
    return {
      label: item.description,
      value: item.name
    };
  });

  return newList ?? [];
};

export const OPTInList = (): OptionList[] => {
  return OPTInOptionList ?? [];
};

export const invertedBusinessMap: Record<string, string> = {
  RTL: "FAL",
  FAL: "FCM" 
}