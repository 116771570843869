import { Tab, Text } from "@jarvis-catalyst/ui-components";
import React from "react";
import IdentityDocumentTab from "../ValidateUserTabs/IdentityDocumentTab";
import { ProfileUsers } from "../../types";
import EmailsTab from "../ValidateUserTabs/EmailsTab";
import PhonesTab from "../ValidateUserTabs/PhonesTab";
import AddressesTab from "../ValidateUserTabs/AddressesTab";
import UsersTab from "../ValidateUserTabs/UsersTab";
import ErrorComponent from "../Error";

type Props = {
  data?: ProfileUsers;
};

const ProfileForm = ({ data }: Props): JSX.Element => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <Text className="">CIAM ID</Text>
          <input type="text" className="form-control" disabled value={data?.profile.ciamId ?? ""} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Text className="">First Name</Text>
          <input type="text" className="form-control" disabled value={data?.profile.firstName ?? ""} />
        </div>

        <div className="col">
          <Text className="">Middle Name</Text>
          <input type="text" className="form-control" disabled value={data?.profile.middleName ?? ""} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Text className="">Paternal Surname</Text>
          <input type="text" className="form-control" disabled value={data?.profile.paternalSurname ?? ""} />
        </div>

        <div className="col">
          <Text className="">Maternal Surname</Text>
          <input type="text" className="form-control" disabled value={data?.profile.maternalSurname ?? ""} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Text className="">Gender</Text>
          <input type="text" className="form-control" disabled value={data?.profile.gender ?? ""} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Text className="">Birthday</Text>
          <input type="text" className="form-control" disabled value={data?.profile.birthday ?? ""} />
        </div>

        <div className="col">
          <Text className="">Birthplace</Text>
          <input type="text" className="form-control" disabled value={data?.profile.birthplace ?? ""} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Text className="">Marital Status</Text>
          <input type="text" className="form-control" disabled value={data?.profile.maritalStatus ?? ""} />
        </div>

        <div className="col">
          <Text className="">Marital Description</Text>
          <input
            type="text"
            className="form-control"
            disabled
            value={data?.profile.maritalStatusDescription ?? ""}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Text className="">Nationality</Text>
          <input type="text" className="form-control" disabled value={data?.profile.nationality ?? ""} />
        </div>
        <div className="col">
          <Text className="">Marital Description</Text>
          <input
            type="text"
            className="form-control"
            disabled
            value={data?.profile.maritalStatusDescription ?? ""}
          />
        </div>
      </div>

      <div style={{ marginTop: "25px" }}>
        <Tab
          pages={[
            {
              title: "Users",
              key: `users`,
              body: <UsersTab users={data?.users} />
            },
            {
              title: "Identity Document",
              key: `identity-document`,
              body: data?.profile.identityDocument.length ? (
                <IdentityDocumentTab identityDocuments={data?.profile.identityDocument} />
              ) : (
                <ErrorComponent isError={404} title="No data found" text="" />
              )
            },
            {
              title: "Emails",
              key: `emails`,
              body: data?.profile.emails.length ? (
                <EmailsTab emails={data?.profile.emails} />
              ) : (
                <ErrorComponent isError={404} title="No data found" text="" />
              )
            },
            {
              title: "Phones",
              key: `phones`,
              body: data?.profile.phones.length ? (
                <PhonesTab phones={data?.profile.phones} />
              ) : (
                <ErrorComponent isError={404} title="No data found" text="" />
              )
            },
            {
              title: "Addresses",
              key: `addresses`,
              body: data?.profile.addresses.length ? (
                <AddressesTab addresses={data?.profile.addresses} />
              ) : (
                <ErrorComponent isError={404} title="No data found" text="" />
              )
            }
          ]}
        />
      </div>
    </div>
  );
};

export default ProfileForm;
