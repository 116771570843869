import { UseMutateFunction, useMutation } from "react-query";
import Swal from "sweetalert2";
import { createBlockUnlockDocumentService } from "../services/createBlockUnlockDocumentService";
import { useQueryApiError } from "../types";
import { useUser } from "./useUser";

type useMutationDocumentType = {
  mutate: UseMutateFunction<unknown, useQueryApiError, any, () => void>;
  isLoadingAction: boolean;
  isErrorAction: boolean;
  ErrorAction: useQueryApiError;
  isSuccessAction: boolean;
};

export const useMutationDocument = (): useMutationDocumentType => {
  const { user } = useUser();
  const {
    mutate,
    isLoading: isLoadingAction,
    isError: isErrorAction,
    error: ErrorAction,
    isSuccess: isSuccessAction
  } = useMutation<unknown, any, any, () => unknown>({
    mutationFn: ({ documentNumber, operation, reason, serviceProvider, documentType }) => {
      Swal.fire({
        title: "Please wait...",
        timer: 5000
      });
      Swal.showLoading();

      return createBlockUnlockDocumentService({
        documentNumber,
        operation,
        reason,
        serviceProvider,
        token: user.token,
        documentType
      });
    },
    onSuccess() {
      Swal.fire({
        icon: "success",
        title: "Registro exitoso",
        text: "La actualización de estado se realizó correctamente"
      });
    },
    onError(error) {
      const { message } = error as useQueryApiError;
      Swal.fire({
        icon: "error",
        title: "Error",
        text: message
      });
    }
  });
  return { mutate, isLoadingAction, isErrorAction, ErrorAction, isSuccessAction };
};
