import Swal from "sweetalert2";
import { UseMutateFunction, UseMutationResult, useMutation } from "react-query";
import { useQueryApiError, ResponseUpdateTemplateService } from "../types";
import { useNavigate } from "react-router";
import { NewVersionConsentConfigType } from "../types/newVersionConsentConfig";
import { updateTemplateService } from "../services/updateTemplateService";

type MutationParams = {
  business: string;
  country: string;
  purpose: string;
  templates: NewVersionConsentConfigType[];
  token: string;
  version?: string;
};

type UpdateTemplateHookType = {
  useUpdateTemplateQuery: UseMutationResult<
    ResponseUpdateTemplateService,
    useQueryApiError,
    MutationParams,
    unknown
  >;
  loading: boolean;
  error: boolean;
  mutate: UseMutateFunction<ResponseUpdateTemplateService, useQueryApiError, MutationParams, unknown>;
};

export const useUpdateTemplate = (): UpdateTemplateHookType => {
  const navigate = useNavigate();
  const useUpdateTemplateQuery = useMutation<
    ResponseUpdateTemplateService,
    useQueryApiError,
    MutationParams,
    unknown
  >({
    mutationFn: async ({ business, country, purpose, templates, token, version }: MutationParams) => {
      return await updateTemplateService({ business, country, purpose, templates, token, version });
    },
    onMutate: () => {
      Swal.fire({
        title: "...",
        html: "Please wait, we are updataing the template.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onSuccess: () => {
      Swal.fire({
        title: "Success!",
        text: "The templates was updated successfully.",
        icon: "success",
        width: "650px",
        confirmButtonText: "Ok",
        didClose() {
          navigate("/consent");
        }
      });
    },
    onError: (error) => {
      Swal.close();
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "Ok"
      });
    }
  });

  return {
    useUpdateTemplateQuery,
    loading: useUpdateTemplateQuery.isLoading,
    error: useUpdateTemplateQuery.isError,
    mutate: useUpdateTemplateQuery.mutate
  };
};
