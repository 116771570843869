import React, { ReactElement } from "react";
import DeleteForm from "../../../components/Form/deleteForm";

const DeleteAccount = (): ReactElement => {
  return (
    <>
      <DeleteForm action="deleteAccount" title="Delete Account" />
    </>
  );
};

export default DeleteAccount;
