import React, { ReactElement } from "react";
import CreatePurposeForm from "../../../components/Form/createPurposeForm";

const CreatePurpose = (): ReactElement => {
  return (
    <>
      <CreatePurposeForm title="Crear Punto de Captura" />
    </>
  );
};

export default CreatePurpose;
