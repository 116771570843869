import React, { ReactElement, useState } from "react";
import { Button, Card, CardBody, Col, Icon, Row } from "@jarvis-catalyst/ui-components";
import { useTemplatesInDraftList } from "../../hooks/useTemplateDraft";
import ListTemplatesModal from "../../components/Modal/listTemplatesModal";
import LoadingComponent from "../../components/Loading";
import { DraftsInProgressDataType } from "../../types";
import { parseDate } from "../../utils/parseDate";
import ErrorComponent from "../../components/Error";
import { ButtonBackCustom } from "../../styled-components/Buttons";
import { useNavigate } from "react-router";
import { approveConsentsOption } from "../../config/data/consentOptions";
import { useUser } from "../../hooks/useUser";
import { validateIfHasPermissionToAccessView } from "../../utils/filterPermission";
import { invertedBusinessMap } from "../../utils/listOptions";

const Draft = (): ReactElement => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const { templatesInDraftUseQuery } = useTemplatesInDraftList();
  const { data: dataFetched, isLoading, isError } = templatesInDraftUseQuery;

  const [approveConsent] = useState(approveConsentsOption);
  const { permission } = useUser();

  const hasValidPermission = approveConsent.some(({ validPermissions }) => {
    return validateIfHasPermissionToAccessView({
      validPermissionsView: validPermissions,
      userPermission: permission,
      action: "publish"
    });
  });

  const [dataModal, setDataModal] = useState<DraftsInProgressDataType>();

  if (openModal) {
    return (
      <ListTemplatesModal
        isReadOnly={false}
        isOpen={openModal}
        setIsOpen={setOpenModal}
        title={""}
        data={dataModal}
      />
    );
  }

  if (isError) {
    return (
      <>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={12} lg={12} xs={12} sm={12}>
            <ErrorComponent
              isError={404}
              title={"Not Found"}
              text="There are no templates waiting for validation"
              redirectTo="/consent"
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={12} lg={12} xs={12} sm={12}>
          <div
            style={{
              maxWidth: "150px"
            }}
          >
            <div className="mt-2 mb-2">
              <ButtonBackCustom onClick={() => navigate("/consent")}>Volver</ButtonBackCustom>
            </div>
          </div>
          <Card className="shadow table-responsive">
            <h2 className="fw-bold mb-2 text-uppercase text-center">Aprobar Consentimientos</h2>
            <hr />
            <CardBody>
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr className="text-uppercase">
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">País</th>
                      <th scope="col">Negocio</th>
                      <th scope="col">Creado El</th>
                      <th scope="col">Creado Por</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFetched?.data.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1}</th>
                          <td>{item.name}</td>
                          <td>{item.country}</td>
                          <td>{invertedBusinessMap[item.business] ?? item.business}</td>
                          <td>{parseDate(item.created_audit?.created_at, "yyyy-MM-dd HH:mm:ss")}</td>
                          <td>{item.created_audit?.created_by}</td>
                          <td style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                              disabled={!hasValidPermission}
                              onClick={() => {
                                setDataModal(item);
                                setOpenModal(true);
                              }}
                            >
                              <Icon name="View" color="white" size={24} />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Draft;
