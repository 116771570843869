import { PERMISSION } from "../constants";

const {
  DELETE_ACCOUNT,
  DELETE_CUSTOMER
} = PERMISSION;

export const deleteUserOption = [
  {
    title: "Delete customer (ciam_id)",
    url: "/delete-account",
    enabled: true,
    validPermissions: [DELETE_CUSTOMER]
  }
];

export const deleteAccountOption = [
  {
    title: "Delete account (mandato apple)",
    url: "/delete-user",
    enabled: true,
    validPermissions: [DELETE_ACCOUNT]
  }
];