import React, { ReactElement } from "react";
import UnlockForm from "../../../components/Form/unlockForm";

const UnlockPassword = (): ReactElement => {
  return (
    <div>
      <UnlockForm action="unlockPassword" title="Unlock Password" />
    </div>
  );
};

export default UnlockPassword;
