import React, { ReactElement } from "react";
import ClearFilters from "./clearFilters";
import SelectAction from "./selectAction";
import { DatePicker } from "./datepicker";    
import './styles.css'

type Dates = {
  startDate: string;
  endDate: string;
}

type FiltersProps = {
  onDateChange: ({startDate, endDate}: Dates) => void;
  handleOnActionChange: (action:string ) => void;
  handleClearFilter: () => void;
  action: string;
  startDate: string;
  endDate: string;
};

const Filters = ({
  onDateChange,
  handleOnActionChange,
  handleClearFilter,
  action,
  startDate,
  endDate
}:FiltersProps): ReactElement => {  
  return ( 
    <div className="filter-container">
      <div className="clear-filter">
        <ClearFilters handleOnDateChange={handleClearFilter}/>
      </div>

      <div className="date-filter">
        <DatePicker
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
        />
      </div> 

      <div className="action-filter">
        <SelectAction handleOnDateChange={handleOnActionChange} currentAction={action}/>
      </div>
    </div>
  );

}
export default Filters
