import axiosInstance from "../config/axiosIntance";
import { BFF_BASE_URL } from "../config/constants";
import { ResponseCreateLockUnlockDocumentService } from "../types";

type Params = {
  documentNumber: string;
  serviceProvider: string;
  operation: string;
  reason: string;
  token: string;
  documentType: string;
};

export const createBlockUnlockDocumentService = async ({
  documentNumber,
  operation,
  reason,
  serviceProvider,
  token,
  documentType
}: Params): Promise<ResponseCreateLockUnlockDocumentService> => {
  const responseFetched = await axiosInstance.post<ResponseCreateLockUnlockDocumentService>(
    `${BFF_BASE_URL}/ciam-tools-bff/audits/blocklist/customers`,
    {
      documentNumber,
      operation,
      reason,
      serviceProvider,
      documentType
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  return responseFetched.data;
};
