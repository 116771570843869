const LOYALTY_POLICY_TEXT = "reglamento";
const PRIVACY_POLICY_TEXT = "política";

import React, {
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";
import { Button, Icon, Text, Switch } from "@jarvis-catalyst/ui-components";
import ModalEditTextWithEditor from "../../../components/Modal/editTextWithEditor";
import { OPTInList } from "../../../utils/listOptions";
import { ConstantValuesToGroup } from "../../../types";
import { UseFormReturn } from "react-hook-form";
import { NewVersionConsentConfigType } from "../../../types/newVersionConsentConfig";

type Props = {
  consent: NewVersionConsentConfigType;
  position: number;
  useStateForm: UseFormReturn<ConstantValuesToGroup, any, undefined>;
  templates: NewVersionConsentConfigType[];
};

const UpdateConsentComponent = ({ consent, position, useStateForm }: Props): ReactElement => {
  const keyTemplate = `template-${position}`;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [titleModal, setTitleModal] = useState("");

  const previousDisplayTextRef = useRef<string>(consent.display_text);
  const previousCategoryTypeRef = useRef<string>(consent.category_type);
  const previousMandatoryRef = useRef<boolean>(consent.is_mandatory);
  const previousLegalTextRef = useRef<string>(consent.legal_text);
  const previousExplicitAgreeRef = useRef<boolean>(consent.rules?.explicitAgree || false);
  const previousVersionRef = useRef<string>(consent.version);

  const { formState, setValue, getValues } = useStateForm;
  const [explicitAgree, setExplicitAgree] = useState(consent?.rules?.explicitAgree);

  const incrementVersion = (version: string) => {
    let newVersion = (Number(version) + 1).toString();
    newVersion = newVersion.padStart(3, '0');
    return newVersion;
  };

  const decreaseVersion = (version: string) => {
    let newVersion = (Number(version) - 1).toString();
    newVersion = newVersion.padStart(3, '0');
    return newVersion;
  }

  const isPurposeNameCmrpuntos: boolean = Boolean(consent.purpose?.purposeName.toLocaleLowerCase().includes("cmrpuntos"));
  const isLoyalty: boolean = consent.display_text ? Boolean(consent.display_text.toLowerCase().includes(LOYALTY_POLICY_TEXT)) : false;
  const isPolicy: boolean = consent.display_text ? Boolean(consent.display_text.toLowerCase().includes(PRIVACY_POLICY_TEXT)) : false;
  const isCMRpuntosTemplate: boolean = (isLoyalty || isPolicy)
  const disabledInput: boolean = isCMRpuntosTemplate ? !isPurposeNameCmrpuntos : false;

  const disableCmrpuntosBase = isPurposeNameCmrpuntos && (isLoyalty || isPolicy)

  useEffect(() => {
    setValue(`${keyTemplate}.position`, position);
    setValue(`${keyTemplate}.group`, Number(consent.group));
    setValue(`${keyTemplate}.simpleText`, consent.category_type);
    setValue(`${keyTemplate}.displayText`, consent.display_text);
    setValue(`${keyTemplate}.mandatory`, consent.is_mandatory ? "yes" : "no");
    setValue(`${keyTemplate}.code`, consent.name);
    setValue(`${keyTemplate}.version`, consent.version);
    setValue(`${keyTemplate}.legalText`, consent.legal_text);
    setValue(`${keyTemplate}.explicitAgree`, explicitAgree? explicitAgree : false);
  });

  useEffect(() => {
    consent.toUpdate = false;

    if (!consent.toCreate) {
      const isCategoryTypeChanged: boolean = previousCategoryTypeRef.current !== consent.category_type;
      const isDisplayTextChanged: boolean = previousDisplayTextRef.current !== consent.display_text;
      const isMandatoryChanged: boolean = previousMandatoryRef.current !== consent.is_mandatory;
      const isExplicitAgreeChanged: boolean = previousExplicitAgreeRef.current !== consent.rules?.explicitAgree;

      if (isCategoryTypeChanged || isDisplayTextChanged || isMandatoryChanged || isExplicitAgreeChanged) {
        consent.toUpdate = true;
      }
    }

    previousCategoryTypeRef.current = consent.category_type;
    previousDisplayTextRef.current = consent.display_text;
    previousMandatoryRef.current = consent.is_mandatory;
    previousExplicitAgreeRef.current = consent.rules?.explicitAgree;
  }, [consent.category_type, consent.display_text, consent.is_mandatory, consent.rules?.explicitAgree]);

  const handleOnSave = (e) => {
    const versionAreEqual = previousVersionRef.current === consent.version;
    const isHigerVersion = Number(consent.version) > Number(previousVersionRef.current);
    const isLegalTextChanged = previousLegalTextRef.current !== consent.legal_text;

    if (!isLegalTextChanged && isHigerVersion) {
      consent.version = decreaseVersion(consent.version);
    }
    
    if (!consent.newElement) {
      consent.toCreate = false;
      if (isLegalTextChanged && versionAreEqual) {
        consent.toCreate = true;
        consent.version = incrementVersion(consent.version);
        consent.is_enabled = false;
        const legalText = e.editor.getData();
        setValue(`${keyTemplate}.legalText`, legalText);
      }
    }
  }

  if (isOpen) {
    return (
      <ModalEditTextWithEditor
        isReadOnly={disabledInput}
        text={getValues(`${keyTemplate}.legalText`)}
        handleOnDestroy={(e: any) => handleOnSave(e)}
        setValue={(value: string) => {
          consent.legal_text = value;
        }}
        isOpen
        setIsOpen={setIsOpen}
        title={titleModal}
      />
    );
  }

  return (
    <>
      <div className="col-sm-2 d-flex justify-content-center">
        <Text className="">Position:</Text>
        <Text className="">{position}</Text>
      </div>
      <div className="col-sm-10 row mt-4 mb-4">
        <div className="mb-2">
          <Text className="">OPT-IN Category where</Text>
          <select
            disabled={disabledInput}
            {...useStateForm.register(`${keyTemplate}.simpleText`, {
              required: true,
              onChange: (e) => {
                e.preventDefault();
                e.stopPropagation();
                consent.category_type = e.target.value;
              }
            })}
            className={`form-control ${formState.errors[`${keyTemplate}`]?.simpleText ? "is-invalid" : ""}`}
          >
            {OPTInList().map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <div className="row">
            <Text className="">Display Text</Text>
            <div className="col-sm-10">
              <input
                disabled={disabledInput}
                {...useStateForm.register(`${keyTemplate}.displayText`, {
                  required: true,
                  onChange: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    consent.display_text = e.target.value;
                  }
                })}
                className={`form-control ${formState.errors[`${keyTemplate}`]?.displayText ? "is-invalid" : ""
                }`}
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-sm-3">
              <Text>Mandatory</Text>
              <select
                disabled={disabledInput}
                className="form-select"
                {...useStateForm.register(`${keyTemplate}.mandatory`, {
                  required: true,
                  onChange: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    consent.is_mandatory = e.target.value === "yes";
                  }
                })}
              >
                <option value={"no"}>No</option>
                <option value={"yes"}>Yes</option>
              </select>
            </div>
            <div className="col-sm-6">
              <Text className="">Code</Text>
              <input
                disabled={disabledInput || disableCmrpuntosBase && !consent.newElement}
                type="text"
                className={`form-control ${formState.errors[`${keyTemplate}`]?.code ? "is-invalid" : ""}`}
                {...useStateForm.register(`${keyTemplate}.code`, {
                  required: true,
                  onChange(event) {
                    event.preventDefault();
                    event.stopPropagation();
                    consent.name = event.target.value;
                  }
                })}
              />
            </div>
            <div className="col-sm-2">
              <Text>Version</Text>
              <input
                disabled
                type="text"
                className={`form-control ${formState.errors[`${keyTemplate}`]?.version ? "is-invalid" : ""}`}
                {...useStateForm.register(`${keyTemplate}.version`, {
                  required: true,
                  onChange(event) {
                    event.preventDefault();
                    event.stopPropagation();
                    consent.version = event.target.value;
                  }
                })}
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-sm-6" style={{ marginTop: "-30px", marginBottom: "-15px" }}>
              <Text>Aceptación Explicita</Text>
              <Switch
                onChange={(value) => {
                  const rules = { ...consent.rules };
                  consent.rules = rules
                  consent.rules.explicitAgree = value;
                  setExplicitAgree(value);
                  setValue(`${keyTemplate}.explicitAgree`, value, { shouldValidate: false });
                }}
                value={Boolean(explicitAgree)}
                size="sm"
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-30px" }}>
          <div className="row">
            <Text>Legal Text</Text>
            <div className="col-sm-10">
              <textarea
                disabled
                className={`form-control ${formState.errors[`${keyTemplate}`]?.legalText ? "is-invalid" : ""
                }`}
                {...useStateForm.register(`${keyTemplate}.legalText`, {
                  required: true
                })}
              />
            </div>
            <div className="col-sm-2 d-flex align-items-center">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsOpen(true);
                  setTitleModal("Edit Legal Text");
                }}
              >
                <Icon name="Edit" color="white" size={24} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateConsentComponent;
