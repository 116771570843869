import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBackCustom } from "../../styled-components/Buttons";

const NotFound = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: "75vh" }}>
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="fs-3">
          {" "}
          <span className="text-danger">Opps!</span> Page not found.
        </p>
        <p className="lead">The page you tried to access could not be found.</p>
        <div className="mt-2 text-center">
          <ButtonBackCustom onClick={() => navigate("/")}>Back</ButtonBackCustom>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
