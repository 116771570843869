import { Text } from "@jarvis-catalyst/ui-components";
import React from "react";
import { IdentityDocument } from "../../types";

type Props = {
  identityDocuments?: IdentityDocument[];
};

const IdentityDocumentTab = ({ identityDocuments }: Props): JSX.Element => {
  return (
    <>
      {identityDocuments?.map((identityDocument) => (
        <div key={identityDocument.id}>
          <div className="row">
            <div className="col">
              <Text className="">ID</Text>
              <input type="text" className="form-control" disabled value={identityDocument.id} />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Text className="">Document Type</Text>
              <input type="text" className="form-control" disabled value={identityDocument.documentType} />
            </div>

            <div className="col">
              <Text className="">Document Type Description</Text>
              <input
                type="text"
                className="form-control"
                disabled
                value={identityDocument.documentTypeDescription}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Text className="">Country Code</Text>
              <input type="text" className="form-control" disabled value={identityDocument.countryCode} />
            </div>

            <div className="col">
              <Text className="">Document Number</Text>
              <input type="text" className="form-control" disabled value={identityDocument.documentNumber} />
            </div>
          </div>
          <hr />
        </div>
      ))}
    </>
  );
};

export default IdentityDocumentTab;
