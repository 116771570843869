import { UseQueryResult, useQuery } from "react-query";
import { useUser } from "./useUser";
import { getAllPurposeService } from "../services/getAllPurpose";
import { PurposeType, useQueryApiError } from "../types";

const keyCache = `listPurpose`;

type PurposeListHookType = {
  purposeListUseQuery: UseQueryResult<PurposeType[], useQueryApiError>;
};

export const usePurposeList = (): PurposeListHookType => {
  const { user } = useUser();
  const purposeListUseQuery = useQuery<PurposeType[], useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getAllPurposeService({ token: user.token });
      return response.data;
    },
    { cacheTime: 10000 }
  );

  return {
    purposeListUseQuery
  };
};
