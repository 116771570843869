import { useContext } from "react";
import UtilsContext from "../context/UtilsContext";
import { UtilsContextType } from "../types";

/*
interface UtilsHookType extends UtilsContextType {}
*/

export const useUtils = (): UtilsContextType => {
  const { serviceProvider, setServiceProvider, setUserName, userName } = useContext(UtilsContext);

  return {
    serviceProvider,
    setServiceProvider,
    setUserName,
    userName
  };
};
