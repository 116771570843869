import React, { ReactNode } from "react";
import { UtilsContextType } from "../types";

const UtilsContext = React.createContext({} as UtilsContextType);

interface Props {
  children: ReactNode | ReactNode[];
}

export function UtilsContextProvider({ children }: Props): React.ReactElement {
  const [serviceProvider, setServiceProvider] = React.useState<string>("");
  const [userName, setUserName] = React.useState<string>();

  return (
    <UtilsContext.Provider value={{ serviceProvider, setServiceProvider, userName, setUserName }}>
      {children}
    </UtilsContext.Provider>
  );
}

export default UtilsContext;
