import React, { useState } from "react";
import { Button, Icon, Input, Switch, Text } from "@jarvis-catalyst/ui-components";
import ModalEditTextWithEditor from "../Modal/editTextWithEditor";
import { RelatedTemplateType } from "../../types";
import { OPTInOptionList } from "../../../src/config/constants";

type Props = {
  item: RelatedTemplateType;
};

const TemplateValidateForm = ({ item }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const renderOPTInOption = (categoryType: string) => {
    return OPTInOptionList.find((optIn) => optIn.value === categoryType)?.label;
  }

  if (isOpen) {
    return (
      <ModalEditTextWithEditor
        isReadOnly={true}
        text={item.legal_text}
        setValue={() => null}
        isOpen
        setIsOpen={setIsOpen}
        title={titleModal}
      />
    );
  }

  return (
    <>
      <div className="col-sm-2 d-flex justify-content-center">
        <Text className="">Posición:</Text>
        <Text className="">{item.position}</Text>
      </div>
      <div className="col-sm-10 row mt-4 mb-4">
        <div className="mb-2">
          <Text className="">Categoría OPT-IN</Text>
          <Input value={renderOPTInOption(item.category_type)} onChange={() => null} disabled />
        </div>
        <div className="mb-2">
          <div className="row">
            <Text className="">Texto Checkbox</Text>
            <div className="col-sm-10">
              <Input value={item.display_text} onChange={() => null} disabled />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-sm-3">
              <Text>Obligatoriedad</Text>
              <Input value={item.is_mandatory ? "Yes" : "No"} onChange={() => null} disabled />
            </div>
            <div className="col-sm-6">
              <Text className="">Código del Texto legal</Text>
              <Input value={item.name} onChange={() => null} disabled />
            </div>
            <div className="col-sm-2">
              <Text>Versión</Text>
              <Input value={item.version} onChange={() => null} disabled />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-sm-6" style={{ marginTop: "-30px", marginBottom: "-15px" }}>
              <Text>Aceptación Explicita</Text>
              <Switch onChange={() => null} value={item.rules.explicitAgree} size="sm" />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-30px" }}>
          <div className="row">
            <Text>Texto Legal</Text>
            <div className="col-sm-10">
              <textarea className="form-control" disabled value={item.legal_text} />
            </div>
            <div className="col-sm-2 d-flex align-items-center">
              <Button
                onClick={() => {
                  setIsOpen(true);
                  setTitleModal("Text Legal");
                }}
              >
                <Icon name="View" color="white" size={24} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateValidateForm;
