import { parseISO, format } from "date-fns";

export const parseDate = (date?: string, dateFormat = "yyyy-MM-dd"): string => {
  if (date) {
    const fechaParseada = parseISO(date);
    const fechaFormateada = format(fechaParseada, dateFormat);
    return fechaFormateada;
  }
  return "";
};
