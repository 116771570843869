import { Card, CardBody, Col, DeniedComponent, Row } from "@jarvis-catalyst/ui-components";
import React, { ReactElement } from "react";

import { useNavigate } from "react-router-dom";
import { ButtonBackCustom } from "../../styled-components/Buttons";

const PermissionDenied = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <Card className="shadow">
            <CardBody>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">ACCESS DENIED</h2>
                <hr />
                <div className="mb-1">
                  <div>
                    <div className="text-center">
                      <p className="fst-italic mb-2">
                        You do not have the necessary permissions to access this section, please contact{" "}
                        <a href="mailto:ciam@Falabella.cl">ciam@Falabella.cl</a>
                      </p>
                      <div>
                        <DeniedComponent message="You dont have permission" />
                      </div>
                    </div>
                    <div className="d-grid">
                      <div className="mt-2 text-center">
                        <ButtonBackCustom onClick={() => navigate("/ciam-tools")}>Back</ButtonBackCustom>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PermissionDenied;
