import axiosInstance from "../config/axiosIntance";
import { ClaimsResponseService } from "../types";

type getClaimsServiceParams = {
  email?: string;
  documentNumber?: string;
  requestedBy?: string;
  serviceProvider: string;
  token: string;
};

export const getClaimsService = async ({
  serviceProvider,
  email,
  documentNumber,
  token,
  requestedBy
}: getClaimsServiceParams): Promise<ClaimsResponseService> => {
  const dataFetched = await axiosInstance.get<ClaimsResponseService>(`/customer/claims`, {
    params: {
      serviceProvider,
      email,
      documentNumber,
      requestedBy
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return dataFetched.data;
};
