import React, { ReactElement } from "react";
import LockForm from "../../../components/Form/lockForm";

const LockPassword = (): ReactElement => {
  return (
    <div>
      <LockForm action="lockPassword" title="Lock Password" />
    </div>
  );
};

export default LockPassword;
