import axiosInstance from "../config/axiosIntance";
import { getDraftByPurposeResponse } from "../types";

type Params = {
  business: string;
  country: string;
  purpose: string;
  token: string;
};

export const getDraftsByPurposeService = async ({
  business,
  country,
  purpose,
  token
}: Params): Promise<getDraftByPurposeResponse> => {
  const dataFetched = await axiosInstance.get<getDraftByPurposeResponse>(
    `/consent-config/draft/checkPurposeInDraft/${purpose}`,
    {
      params: { business, country },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return dataFetched.data;
};
