import axiosInstance from "../config/axiosIntance";
import { BFF_BASE_URL } from "../config/constants";
import { ResponseApproveDraftService } from "../types";
import { v4 as uuid } from "uuid";

type Params = {
  draftId?: string;
  business?: string;
  country?: string;
  token?: string;
};

export const approveDraftService = async ({
  business,
  country,
  draftId,
  token
}: Params): Promise<ResponseApproveDraftService> => {
  const responseService = await axiosInstance.put<ResponseApproveDraftService>(
    `${BFF_BASE_URL}/ciam-tools-bff/consent-config/draft/${draftId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        business,
        country,
        requestedBy: "Jarvis",
        "X-txRef": `jarvis-${uuid()}`
      }
    }
  );

  return responseService.data;
};
