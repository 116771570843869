import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, Card, CardBody, Col, Row, Text } from "@jarvis-catalyst/ui-components";
import { usePurposeMutation } from "../../hooks/usePurpose";
import { useUser } from "../../hooks/useUser";
import { createPurposeOption } from "../../config/data/consentOptions";
import { validateIfHasPermissionToAccessView } from "../../utils/filterPermission";

type Props = {
  title: string;
};

type Inputs = {
  name: string;
  description: string;
};

const CreatePurposeForm = ({ title }: Props): ReactElement => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { mutate, isSuccessAction } = usePurposeMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<Inputs>();

  const [createPurpose] = useState(createPurposeOption);
  const { permission } = useUser();

  const hasValidPermission = createPurpose.some(({ validPermissions }) => {
    return validateIfHasPermissionToAccessView({
      validPermissionsView: validPermissions,
      userPermission: permission,
      action: "create"
    });
  });

  const onSubmit: SubmitHandler<Inputs> = async ({ name, description }) => {
    await mutate({ name, description, currentUser: user });
  };

  useEffect(() => {
    if (isSuccessAction) {
      reset();
    }
  }, [isSuccessAction]);

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <Card className="shadow">
            <CardBody>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">{title}</h2>
                <hr />
                <form className="mb-1" onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-1">
                    <Text>Nombre:</Text>
                    <input
                      className={`form-control ${errors.name ? "is-invalid" : ""}`}
                      type="text"
                      {...register("name", { required: true, maxLength: 30 })}
                    />
                  </div>
                  <div className="mb-3">
                    <Text>Descripción:</Text>
                    <input
                      type="text"
                      className={`form-control ${errors.description ? "is-invalid" : ""}`}
                      {...register("description", { required: true, maxLength: 100 })}
                    />
                  </div>
                  <div className="d-grid">
                    <Button
                      color="primary"
                      className="mt-2 text-center"
                      disabled={!hasValidPermission}
                      type="submit"
                    >
                      Crear Punto de Captura
                    </Button>
                    <Button
                      disabled={false}
                      color="bordered"
                      type="button"
                      className="mt-2 text-center"
                      onClick={() => navigate("/consent")}
                    >
                      Volver
                    </Button>
                  </div>
                </form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreatePurposeForm;
