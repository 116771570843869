import React, {ReactElement, useState} from "react";
import { Dropdown, IconChevronDown } from "@jarvis-catalyst/ui-components";

type SelectActionProps = {
  handleOnDateChange: (action: string) => void;
  currentAction: string;
};

const SelectAction = ({ handleOnDateChange, currentAction}: SelectActionProps): ReactElement => {
  const [action, setAction] = useState(currentAction);

  const onSelected = (actionSelected:string) => {
    setAction(actionSelected)
    handleOnDateChange(actionSelected)
  }

  return (
    <Dropdown
      onSelected={ (actionSelected) => onSelected(actionSelected) }
      selectedValue={action}
      type="input"
      title="Select Action"
      options={[
        { value: "ALL", label: "All" },
        { value: "POST", label: "POST" },
        { value: "UPDATE", label: "UPDATE" },
        { value: "DELETE", label: "DELETE" },
        { value: "PUT", label: "PUT" },
        { value: "PATCH", label: "PATCH" },
      ]}
      iconRight={<IconChevronDown />}
    />
  )
}

export default SelectAction;