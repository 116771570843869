import axiosInstance from "../config/axiosIntance";
import { DocumentAndAccountHistoryType } from "../types";

type Params = {
  token: string;
};

export const getDocumentAndAccountHistoryService = async ({
  token
}: Params): Promise<DocumentAndAccountHistoryType> => {
  const dataFetched = await axiosInstance.get<DocumentAndAccountHistoryType>("/audits/blocklist", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return dataFetched.data;
};
