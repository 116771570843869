import React from "react";
import { Phone } from "../../types";
import { Text } from "@jarvis-catalyst/ui-components";

type Props = {
  phones?: Phone[];
};

const PhonesTab = ({ phones }: Props): JSX.Element => {
  return (
    <>
      {phones?.map((phone) => (
        <div key={phone.id}>
          <div className="row">
            <div className="col">
              <Text className="">ID</Text>
              <input type="text" className="form-control" disabled value={phone.id} />
            </div>

            <div className="col">
              <Text className="">Phone Type</Text>
              <input type="text" className="form-control" disabled value={phone.phoneType} />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Text className="">Phone Type Description</Text>
              <input type="text" className="form-control" disabled value={phone.phoneTypeDescription} />
            </div>

            <div className="col">
              <Text className="">Phone Number</Text>
              <input type="text" className="form-control" disabled value={phone.phoneNumber} />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Text className="">Verified Phone Number</Text>
              <input
                type="text"
                className="form-control"
                disabled
                value={String(phone.verifiedPhoneNumber)}
              />
            </div>

            <div className="col">
              <Text className="">Preferred Phone Number</Text>
              <input
                type="text"
                className="form-control"
                disabled
                value={String(phone.preferredPhoneNumber)}
              />
            </div>
          </div>
          <hr />
        </div>
      ))}
    </>
  );
};

export default PhonesTab;
