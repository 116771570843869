import { useContext, useEffect } from "react";
import jwt from "jsonwebtoken";
import { v4 as uuidv4 } from "uuid";
import { eventTypes } from "../config/constants";
import UserContext from "../context/UserContext";

interface JWTPayload extends jwt.JwtPayload {
  name: string;
  email: string;
}

export const ApplicationLoaded = (): null => {
  useEffect(() => {
    const message = {
      eventId: uuidv4(),
      eventType: eventTypes.APPLICATION_LOADED,
      data: {}
    };
    window.parent.postMessage(message, "*");
  }, []);
  return null;
};

export const EventFactory = (): null => {
  const { setUser, setPermission, setTenantKey } = useContext(UserContext);

  useEffect(() => {
    // eslint-disable-next-line
    const eventListener = (event: any) => {
      const eventType = event.data.eventType;
      const eventData = event.data;

      switch (eventType) {
        case eventTypes.USER_DETAILS_CHANGE: {
          const cleanToken = eventData.data.__raw?.replace("Bearer ", "");
          const decoded = jwt.decode(cleanToken, {
            json: true
          }) as JWTPayload;

          setUser({ email: decoded.email, name: decoded.name, token: cleanToken });
          break;
        }
        case eventTypes.TENANT_CHANGE:
          setTenantKey(eventData.data.id);
          break;
        case eventTypes.USER_PERMISSIONS_CHANGE:
          setPermission(eventData.data.tenant);
          break;
        default:
          false;
      }
    };

    window.addEventListener("message", eventListener);
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  return null;
};
