import React, { ReactElement, useState} from "react";
import {
  Input,
  Button,
  Tooltip,
} from "@jarvis-catalyst/ui-components";

const formatDate = (date: Date, monthsAgo: number = 0):string => {
  const adjustedMonth = String(date.getMonth() - monthsAgo + 1).padStart(2, "0");
  return `${date.getFullYear()}-${adjustedMonth}-${String(date.getDate()).padStart(2, "0")}`;
};

type DatePickerProps = {
  onDateChange: (filter: { startDate: string, endDate: string }) => void;
  startDate: string;
  endDate: string;
};

const DatePicker = ({ onDateChange, startDate, endDate }: DatePickerProps ): ReactElement => {
  
  const [isOpen, setIsOpen] = useState(false)
  const [datepicker, setDatepicker] = useState({
    startDate: startDate,
    endDate: endDate,
  })

  const handleStartDate = (newStartDate:string) => {
    setDatepicker({ ...datepicker, startDate: newStartDate })
  }

  const handleEndate = (newEndDate:string) => {
    setDatepicker({ ...datepicker, endDate: newEndDate })
  }

  const handleOnOut = () => {
    onDateChange({...datepicker})
    setIsOpen(false)
  }

  const handleConfirmDates = () => {    
    setIsOpen(false)
    onDateChange({...datepicker})
  }

  const renderStartDate = () => {
    return (
      <Button className="date-button" color="bordered" onClick={() => setIsOpen(!isOpen)}>
        {"From: " + datepicker.startDate}
      </Button>
    )
  }

  const renderEndDate = () => {
    return (
      <Button className="date-button" color={"bordered"} onClick={() => setIsOpen(!isOpen)}>
        {"To: " + datepicker.endDate}
      </Button>
    )
  }

  const renderDatePickers = () => {
    return (
      <Tooltip
        isOpen={isOpen}
        width={250}
        position="bottom-start"
        type="dialog"
        dialogColor="info"
        dialogMessage="Select date range"
        onOut={() => handleOnOut()}
        content={(
          <>
            <Input 
              id="start-date"
              type="date"
              label="From"
              onChange={(e) => handleStartDate(e.target.value)}
              value={datepicker.startDate} />

            <Input
              id="end-date"
              type="date"
              label="To"
              onChange={(e) => handleEndate(e.target.value)}
              value={datepicker.endDate} />

            <Button
              className="confirm-dates"
              size="sm"
              color="primary"
              onClick={() => handleConfirmDates()}>confirmar</Button>              
          </>

        )}
      >
        <Button className="date-button" color={"primary"} onClick={() => { setIsOpen(!isOpen) }}>
          Filter by date
        </Button>
      </Tooltip>
    )
  }

  return (
    <div>
      {renderDatePickers()}
      {renderStartDate()}
      {renderEndDate()}
    </div>
  )
}

export { DatePicker, formatDate }




