import { UseQueryResult, useQuery } from "react-query";
import { Properties, useQueryApiError } from "../types";
import { getAllAuditsService } from "../services/getAllAuditsService";
import { useUser } from "./useUser";

type AuditHookType = {
  auditUseQuery: UseQueryResult<Properties[], useQueryApiError>;
};

type filtersType = {
  page: number;
  startDate?: string;
  endDate?: string;
  action: string;
}

export const useAuditService = (filters: filtersType): AuditHookType => {
  
  try {
    const { page, startDate, endDate, action } = filters;
    const { user } = useUser();
    const auditUseQuery = useQuery<Properties[], useQueryApiError>(
      [page, startDate, endDate, action],
      async () => {
        const response = await getAllAuditsService({
          page,
          startDate,
          endDate,
          action,
          token: user.token,
        });
        return response.data;
      },
      { cacheTime: 5000 },
    );
  
    return {
      auditUseQuery
    };  
  } catch (error) {
    console.log(error)
  }
};
