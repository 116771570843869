import Swal from "sweetalert2";
import { UseMutationResult, useMutation } from "react-query";
import { useQueryApiError, ResponseClientsUnlockService, UserSelectedLockBlock } from "../types";
import { clientLockUnlockService } from "../services/unlockUserList";
import { useUser } from "./useUser";

type MutationParams = {
  users: UserSelectedLockBlock[];
  serviceProvider: string;
  operation: string;
};

type ClientsHookType = {
  useMutationLockUnlock: UseMutationResult<
    ResponseClientsUnlockService,
    useQueryApiError,
    MutationParams,
    unknown
  >;
};

export const useClientsLockUnlock = (): ClientsHookType => {
  const { user: contextUser } = useUser();

  const useMutationLockUnlock = useMutation<
    ResponseClientsUnlockService,
    useQueryApiError,
    MutationParams,
    unknown
  >({
    mutationFn: async ({ serviceProvider, operation, users }: MutationParams) => {
      return await clientLockUnlockService({
        users,
        operation,
        token: contextUser.token,
        serviceProviderName: serviceProvider
      });
    },
    onMutate: () => {
      Swal.fire({
        title: "...",
        html: "Por favor espera, estamos procesando la solicitud...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onSuccess: () => {
      Swal.fire({
        title: "Actualización Exitosa!.",
        text: "Las cuentas seleccionadas han sido procesadas correctamente.",
        icon: "success",
        width: "650px",
        confirmButtonText: "Ok"
      });
    },
    onError: () => {
      Swal.fire({
        title: "Error!",
        text: "Ha ocurrido un error al procesar la solicitud.",
        icon: "error",
        width: "650px",
        confirmButtonText: "Ok"
      });
    }
  });

  return {
    useMutationLockUnlock
  };
};
