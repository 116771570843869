import { DataResponseService, UserType } from "../types";
import { BFF_BASE_URL } from "../config/constants";
import axiosInstance from "../config/axiosIntance";

export type DeleteAccountParams = {
  email: string;
  serviceProvider: string;
  nroDocumento?: string;
  tipoDocumento?: string;
  currentUser: UserType;
};

export const deleteAccountService = async ({
  email,
  serviceProvider,
  nroDocumento,
  tipoDocumento,
  currentUser
}: DeleteAccountParams): Promise<DataResponseService> => {
  const dataFetched = await axiosInstance.post<DataResponseService>(
    `${BFF_BASE_URL}/ciam-tools-bff/account/delete`,
    {
      username: email,
      serviceProvider,
      documentNumber: nroDocumento,
      documentType: tipoDocumento
    },
    {
      headers: {
        Authorization: `Bearer ${currentUser.token}`
      }
    }
  );

  return dataFetched.data;
};
