import React from "react";

type Props = {
  width?: string;
  height?: string;
  marginTop?: string;
};

const LoadingComponent = ({ height = "3rem", width = "3rem", marginTop = "5rem" }: Props): JSX.Element => {
  return (
    <div className="text-center">
      <div
        className="spinner-border text-primary"
        role="status"
        style={{
          width,
          height,
          marginTop
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingComponent;
