import { ConstantValuesToGroup } from "../../types";

/* posicion 1: corresponde a TyC-eco
 * posicion 2: corresponde a PdP-eco
 */

export const consentCheckBox: ConstantValuesToGroup = {
  loyaltyRule: {
    name: "loyalty_rule",
    group: 1,
    mandatory: "no",
    editable: false,
    simpleText: "PdP-eco",
    code: "TyC_CMRPuntos",
    displayText: "Quiero ser parte del programa CMR Puntos (Ver Reglamento)",
    version: "001",
    legalText: "Texto en definición",
    position: 1,
    explicitAgree: true,
    extraData: {
      loyaltyRule: true,
    }
  },
  privPolicy: {
    name: "policy_rule",
    group: 1,
    mandatory: "no",
    editable: false,
    simpleText: "PdP-eco",
    code: "PdP_CMRPuntos",
    displayText: "y con ello recibir novedades de las Empresas Falabella según Política de Privacidad",
    version: "001",
    legalText: "Texto en definición",
    position: 2,
    explicitAgree: true,
    extraData: {
      privPolicy: true,
    }
  },
  newElement: {
    name: "NewElement",
    mandatory: "no",
    group: 0,
    editable: true,
    simpleText: "",
    code: "",
    displayText: "",
    version: "001",
    legalText: "",
    explicitAgree: false,
    position: 0
  }
};
