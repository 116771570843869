import * as React from "react";
import { UserContextProvider } from "../../context/UserContext";
import { UtilsContextProvider } from "../../context/UtilsContext";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props): React.ReactElement => {
  return (
    <UtilsContextProvider>
      <UserContextProvider>
        <div className="container-xxl mt-5">{children}</div>
      </UserContextProvider>
    </UtilsContextProvider>
  );
};

export default Layout;
