import React, { ReactElement, useEffect } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router";
import { UnlockLocationState } from "../../../types";
import { useQueryGetDocumentStatus } from "../../../hooks/useQueryGetDocumentStatus";
import LoadingComponent from "../../../components/Loading";
import ErrorComponent from "../../../components/Error";
import { Card, CardBody, Col, Row, Text } from "@jarvis-catalyst/ui-components";
import { ButtonBackCustom } from "../../../styled-components/Buttons";
import { parseDate } from "../../../utils/parseDate";

const ValidateDocumentAction = (): ReactElement => {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  const { form } = location.state as UnlockLocationState;

  const { documentNumberUseQuery } = useQueryGetDocumentStatus({
    documentNumber: form.nroDocumento,
    serviceProvider: form.serviceProvider
  });

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, [location]);

  if (documentNumberUseQuery.isLoading) {
    return (
      <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
        <LoadingComponent />
        Loading document status....
      </div>
    );
  }

  if (documentNumberUseQuery.isError) {
    return (
      <ErrorComponent
        isError={documentNumberUseQuery.error.statusCode}
        title={documentNumberUseQuery.error.message}
        redirectTo={"/user-management"}
      />
    );
  }

  const {
    businessUnit,
    countryCode,
    createdAt,
    documentNumber,
    notified,
    operation,
    reason,
    requestedBy,
    documentType
  } = documentNumberUseQuery.data?.data ?? {};

  return (
    <div>
      {
        <>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={8} lg={8} xs={12}>
              <div
                style={{
                  maxWidth: "150px"
                }}
              >
                <div className="mt-2 mb-2">
                  <ButtonBackCustom onClick={() => navigate("/user-management")}>Volver</ButtonBackCustom>
                </div>
              </div>
              <Card className="shadow">
                <CardBody>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-2 text-uppercase text-center">
                      Estado de bloqueo de documento
                    </h2>
                    <hr />
                    <div className="row">
                      <div className="col">
                        <Text className="">Código de país:</Text>
                        <input type="text" className="form-control" disabled value={countryCode} />
                      </div>
                      <div className="col">
                        <Text className="">Negocio:</Text>
                        <input type="text" className="form-control" disabled value={businessUnit} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Text className="">Tipo Documento:</Text>
                        <input type="text" className="form-control" disabled value={documentType} />
                      </div>
                      <div className="col">
                        <Text className="">Número de documento:</Text>
                        <input type="text" className="form-control" disabled value={documentNumber} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Text className="">Estado:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={operation === "lock" ? "Bloqueado" : "Desbloqueado"}
                        />
                      </div>
                      <div className="col">
                        <Text className="">Estado creado por:</Text>
                        <input type="text" className="form-control" disabled value={requestedBy} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Text className="">Estado creado el:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={parseDate(createdAt, "yyyy-MM-dd hh:ss")}
                        />
                      </div>
                      <div className="col">
                        <Text className="">¿Fue notificado?:</Text>
                        <input type="text" className="form-control" disabled value={notified ? "Sí" : "No"} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Text className="">Razón:</Text>
                        <textarea className="form-control" disabled value={reason} />
                      </div>
                    </div>
                  </div>
                  <hr />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      }
    </div>
  );
};

export default ValidateDocumentAction;
