import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "@jarvis-catalyst/ui-components";
import { UnlockLocationState } from "../../../types";
import { useUser } from "../../../hooks/useUser";
import { useConsent } from "../../../hooks/useConsent";
import ProfileForm from "../../../components/Form/profileForm";
import LoadingComponent from "../../../components/Loading";
import ErrorComponent from "../../../components/Error";
import { DeleteButtonCustom } from "../../../styled-components/Buttons";
import { useUnlock } from "../../../hooks/useUnlock";

const UnlockAction = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { form, action } = location.state as UnlockLocationState;
  const { user } = useUser();
  const { setFetched, getProfile, consentQuery } = useConsent({
    ...form,
    currentUser: user
  });
  const { isLoading: isLoadingProfile, isError: isErrorProfile, error } = consentQuery;
  const { mutate, isLoadingAction } = useUnlock();

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
    setFetched(true);
  }, [location]);

  if (isLoadingProfile) {
    return (
      <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
        <LoadingComponent />
        Loading Profile....
      </div>
    );
  }

  if (isErrorProfile) {
    return <ErrorComponent isError={error.statusCode} title={error.message} redirectTo={"/unlockPassword"} />;
  }

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={8} xs={12}>
          <Card className="shadow">
            <CardBody>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">Validate Account</h2>
                <hr />
                <ProfileForm data={getProfile} />
                <div className="d-grid">
                  <DeleteButtonCustom
                    className="mt-2 text-center"
                    disabled={isLoadingAction}
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: "The user will not be able to login",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#d33",
                        cancelButtonColor: "#5266ff",
                        confirmButtonText: "Yes, unlock it!",
                        showLoaderOnConfirm: false,
                        preConfirm: () => {
                          Swal.fire({
                            title: "...",
                            html: "Please wait...",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                              Swal.showLoading();
                            }
                          });
                          mutate({ action, form, currentUser: user });
                        }
                      })
                    }
                  >
                    Unlock Password
                  </DeleteButtonCustom>
                  <Button
                    color="bordered"
                    type="button"
                    className="mt-2 text-center"
                    disabled={isLoadingAction}
                    onClick={() => navigate("/unlockPassword")}
                  >
                    Previous Step
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UnlockAction;
