import { UseQueryResult, useQuery } from "react-query";
import { DocumentAndAccountHistoryType, DocumentAndAccountItemType, useQueryApiError } from "../types";
import { getDocumentAndAccountHistoryService } from "../services/getDocumentAndAccountHistoryService";
import { useUser } from "./useUser";
import { useState } from "react";
import { parseDate } from "../utils/parseDate";

type UseDraftHookType = {
  documentAndAccountHistoryQuery: UseQueryResult<DocumentAndAccountHistoryType, useQueryApiError>;
  dataFetched: DocumentAndAccountItemType[];
  isLoading: boolean;
  isError: boolean;
  error: useQueryApiError | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataFiltered: DocumentAndAccountItemType[];
};

export const useDocumentAndAccountHistory = (): UseDraftHookType => {
  const { user } = useUser();
  const keyCache = `documentAndAccountHistory`;
  const [dataFiltered, setDataFiltered] = useState<DocumentAndAccountItemType[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();

    const data = documentAndAccountHistoryQuery.data?.data;

    const dataFiltered = data?.filter((item) => {
      const formatDate = parseDate(item.createdAt, "dd/MM/yyyy");

      return (
        item.userName?.toLowerCase().includes(value) ||
        item.documentNumber?.toLowerCase().includes(value) ||
        item.createdBy?.toLowerCase().includes(value) ||
        formatDate?.includes(value)
      );
    });

    setDataFiltered(dataFiltered ?? []);
  };

  const documentAndAccountHistoryQuery = useQuery<DocumentAndAccountHistoryType, useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getDocumentAndAccountHistoryService({ token: user.token });
      setDataFiltered(response.data);
      return response;
    },
    { cacheTime: 1000 }
  );

  return {
    documentAndAccountHistoryQuery,
    dataFetched: documentAndAccountHistoryQuery.data?.data ?? [],
    isLoading: documentAndAccountHistoryQuery.isLoading,
    isError: documentAndAccountHistoryQuery.isError,
    error: documentAndAccountHistoryQuery.error,
    handleChange,
    dataFiltered
  };
};
