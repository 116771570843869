import { Card, CardBody, Col, Row } from "@jarvis-catalyst/ui-components";
import React from "react";
import ProfileForm from "../Form/profileForm";
import { ProfileUsers } from "../../types";

type Props = {
  data?: ProfileUsers;
};

const ProfileTab = ({ data }: Props): JSX.Element => {
  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col md={10} lg={10} xs={10} sm={10}>
        <Card className="shadow">
          <h2 className="fw-bold mb-2 text-uppercase text-center">Profile</h2>
          <CardBody>
            <ProfileForm data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ProfileTab;
