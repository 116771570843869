import axios from "axios";
import { v4 as uuid } from "uuid";
import { BFF_BASE_URL } from "./constants";

export const axiosInstance = axios.create({
  baseURL: `${BFF_BASE_URL}/ciam-tools-bff`,
  headers: {
    "Content-Type": "application/json",
    "X-txRef": `jarvis-${uuid()}`
  }
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { data } = error.response.data;

    if (error.response.status != 500) {
      return Promise.reject({ message: data.message, statusCode: error.response.status });
    }

    return Promise.reject({ message: data.message ?? error.message, statusCode: error.response.status });
  }
);

export default axiosInstance;
