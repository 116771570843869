import React from "react";
import { ConsentResponse, RelatedConsents } from "../../types";
import { Text } from "@jarvis-catalyst/ui-components";

type Props = {
  consent: ConsentResponse | RelatedConsents;
};

const ConsentBaseForm = ({ consent }: Props): JSX.Element => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <Text className="">Country Code</Text>
          <input type="text" className="form-control" disabled value={consent.countryCode} />
        </div>
        <div className="col">
          <Text className="">Business Short Name</Text>
          <input type="text" className="form-control" disabled value={consent.businessShortName} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Consent Date</Text>
          <input type="text" className="form-control" disabled value={consent.consentDate} />
        </div>
        <div className="col">
          <Text className="">Consent Date Offset</Text>
          <input type="text" className="form-control" disabled value={consent.consentDateOffset} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Consent Expiration Date</Text>
          <input type="text" className="form-control" disabled value={consent.consentExpirationDate} />
        </div>
        <div className="col">
          <Text className="">ConsentID</Text>
          <input type="text" className="form-control" disabled value={consent.consentId} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Consent Status Description</Text>
          <input type="text" className="form-control" disabled value={consent.consentStatusDescription} />
        </div>
        <div className="col">
          <Text className="">Consent Status ID</Text>
          <input type="text" className="form-control" disabled value={consent.consentStatusId} />
        </div>
      </div>
    </div>
  );
};

export default ConsentBaseForm;
