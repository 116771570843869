
type Schema = {
  key?: string;
  defaultValue?: any;
  title?: string;
  classNameHeader?: string;
  classNameCell?: string;
};

const tableSchema: Array<Schema> = [
  {
    key: 'id',
    title: 'id',
    classNameHeader: 'text-center',
    classNameCell: 'text-center audit-id',
  },
  {
    key: 'type',
    title: 'Type',
    classNameHeader: 'text-center',
    classNameCell: 'text-center',
  },
  {
    key: 'path',
    title: 'Path',
    classNameHeader: 'text-center',
    classNameCell: 'text-center',
  },
  {
    key: 'description',
    title: 'Description',
    classNameHeader: 'text-center',
    classNameCell: 'text-center',
  },
  {
    key: 'createdBy',
    title: 'Created by',
    classNameHeader: 'text-center',
    classNameCell: 'text-center',
  }];

export  default tableSchema