import { useContext } from "react";
import UserContext from "../context/UserContext";
import { PermissionType, UserType } from "../types";

type UserHookType = {
  user: UserType;
  currentUserEmail?: string;
  setUser: (value: UserType) => void;
  tenantKey?: string;
  permission: PermissionType;
};

export const useUser = (): UserHookType => {
  const { user, setUser, tenantKey, permission } = useContext(UserContext);

  return {
    user,
    currentUserEmail: user.email,
    setUser,
    tenantKey,
    permission
  };
};
