import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ConsentChildrenType, SearchConsentFormType } from "../types";
import { consentCheckBox } from "../config/data/consentCheckBox";

export type ConsentContextType = {
  searchConsentForm: SearchConsentFormType;
  setSearchConsentForm: (value: SearchConsentFormType) => void;
  consentChildren: ConsentChildrenType[];
  setConsentChildren: Dispatch<SetStateAction<ConsentChildrenType[]>>;
  groups: (string | number)[];
  setGroups: Dispatch<SetStateAction<(string | number)[]>>;
  addElementToGroup: (groupParam: number) => void;
  addGroupAndElement: (lastGroup: number) => void;
  removeElementByGroup: (itemPosition?: number) => void;
  filterByGroup: (group: number) => ConsentChildrenType[];
  positionsIntoGrup: (group: number) => (number | undefined)[];
  moveElementInGroup: (grupo: any, posicionOriginal: any, nuevaPosicion: any) => void;
};

const ConsentContext = React.createContext({} as ConsentContextType);

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export function ConsentContextProvider({ children }: Props): React.ReactElement {
  const [searchConsentForm, setSearchConsentForm] = useState(CONSENT_FORM_INITIAL_STATE);
  const [consentChildren, setConsentChildren] = useState(CONSENT_CHILDREN_INITIAL_STATE);

  const [groups, setGroups] = useState<(string | number)[]>([]);

  const addElementToGroup = (groupParam: number) => {
    const array = [...consentChildren];

    const groupParamArray = array.filter((item) => item.group === groupParam);

    array.push({
      ...consentCheckBox.newElement,
      group: groupParam,
      position: groupParamArray.length + 1
    });

    setConsentChildren(array);
  };

  const addGroupAndElement = (lastGroup: number) => {
    const newListarray = [...consentChildren];
    newListarray.push({
      ...consentCheckBox.newElement,
      group: lastGroup + 1,
      position: consentChildren.length + 1
    });

    setConsentChildren(newListarray);
  };

  const removeElementByGroup = (itemPosition?: number) => {
    const newList = [...consentChildren];
    const newArray = newList.filter((item) => item.position !== itemPosition);

    setConsentChildren(newArray);
  };

  const moveElementInGroup = (grupo: number | string, originalPosition: number, newPosition: number) => {
    const array = [...consentChildren];
    const groupArray = array.filter((item) => item.group === grupo);
    const element = groupArray.find((item) => item.position === originalPosition);

    if (element) {
      const index = array.indexOf(element);
      array.splice(index, 1);
      array.splice(newPosition - 1, 0, element);

      // Actualizar las posiciones de los elementos después de realizar el cambio
      array.forEach((item, i) => {
        if (item.group === grupo) {
          item.position = i + 1;
        }
      });
    }

    setConsentChildren(array);
  };

  const filterByGroup = (group: number) => {
    return consentChildren.filter((consent) => consent.group === group);
  };

  const positionsIntoGrup = (group: number) => {
    const templatesInGroup = filterByGroup(group);

    const arrayPositions = Array.from(
      new Set(
        templatesInGroup.map(function (consent) {
          return consent.position;
        })
      )
    );

    return arrayPositions;
  };

  useEffect(() => {
    setGroups(
      Array.from(
        new Set(
          consentChildren.map(function (consent) {
            return consent.group;
          })
        )
      )
    );
  }, [consentChildren]);

  return (
    <ConsentContext.Provider
      value={{
        searchConsentForm,
        setSearchConsentForm,
        consentChildren,
        setConsentChildren,
        groups,
        setGroups,
        removeElementByGroup,
        addGroupAndElement,
        addElementToGroup,
        filterByGroup,
        positionsIntoGrup,
        moveElementInGroup
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
}

const CONSENT_FORM_INITIAL_STATE: SearchConsentFormType = {
  country: "",
  business: "",
  purpose: "",
  loyaltyRule: false,
  privPolicy: false
};

const CONSENT_CHILDREN_INITIAL_STATE: ConsentChildrenType[] = [];

export default ConsentContext;
