import { UseQueryResult, useQuery } from "react-query";
import { getDraftApprovedByPurposeNameResponse, useQueryApiError } from "../types";
import { getDraftsApprovedByPurposeName } from "../services/getDraftsApprovedByPurposeName";
import { ConsentConfigBaseType, DraftConsentConfigType } from "../types/newVersionConsentConfig";

type Params = {
  purposeName: string;
  country: string;
  business: string;
  token: string;
};

type DraftsApprovedByPurposeNameHookType = {
  approvedDraftApprovedQuery: UseQueryResult<getDraftApprovedByPurposeNameResponse, useQueryApiError>;
  data: ConsentConfigBaseType[];
  draftData?: DraftConsentConfigType;
};

export const useDraftsApprovedByPurposeName = ({
  purposeName,
  country,
  business,
  token
}: Params): DraftsApprovedByPurposeNameHookType => {
  const keyCache = `draftsapproved-${purposeName}-${country}-${business}`;

  const approvedDraftApprovedQuery = useQuery<getDraftApprovedByPurposeNameResponse, useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getDraftsApprovedByPurposeName({
        business,
        country,
        draftName: `${country}-${business}-${purposeName}`.toLowerCase(),
        purposeName,
        token
      });

      return response;
    },
    { cacheTime: 500, retry: 0 }
  );
  
  return {
    data: approvedDraftApprovedQuery.data?.data.at(0)?.related_templates || [],
    draftData: approvedDraftApprovedQuery.data?.data.at(0),
    approvedDraftApprovedQuery
  };
};
