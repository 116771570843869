import { ConsentResultType } from "../types";

export const filterTemplatesByCategory = (
  templates: ConsentResultType[],
  category: string
): ConsentResultType | undefined => {
  return templates.find((template) => template.templateCategoryType === category);
};

export const filterTemplatesByItemOrder = (
  templates: ConsentResultType[],
  itemOrder: number
): ConsentResultType | undefined => {
  return templates.find((template) => template.itemOrder === itemOrder);
};
