import React, { ReactElement, useState } from "react";
import {
  Table,
  Pagination,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Row
} from "@jarvis-catalyst/ui-components";
import tableSchema from "./tableSchema";
import { useAuditService } from "../../hooks/useAuditService";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/Error";
import Filters from "./filters/index";
import { Properties } from "../../types";
import { parseDate } from "../../utils/parseDate";

const PAGE_SIZE = 12;

const fillEmptyCells = (data: Array<unknown>) => {
  const emptyCell = "\u00A0";
  const diff = PAGE_SIZE - data.length;

  for (let i = 0; i < diff; i++) {
    data.push({
      id: "",
      type: emptyCell
    });
  }
};

type FilterType = {
  page: number;
  startDate: string;
  endDate: string;
  action: string;
};

const initialState: FilterType = {
  page: 1,
  startDate: parseDate(new Date().toISOString()),
  endDate: parseDate(new Date().toISOString()),
  action: "ALL"
};

const Audit = (): ReactElement => {
  const [filters, setFilters] = useState(initialState);
  const { auditUseQuery } = useAuditService(filters);

  const { isLoading, isError, error } = auditUseQuery;

  const handleClearFilter = () => {
    setFilters(initialState);
  };

  type Dates = {
    startDate: string;
    endDate: string;
  };

  const onDateChange = ({ startDate, endDate }: Dates) => {
    setFilters((prevState) => ({ ...prevState, startDate, endDate }));
  };

  const handleOnActionChange = (action: string) => {
    setFilters((prevState) => ({ ...prevState, action: action.value }));
  };

  const handleOnChangePagination = (page: number) => {
    setFilters((prevState) => ({ ...prevState, page: Number(page) }));
  };

  if (isLoading) {
    return (
      <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
        <LoadingComponent />
        Loading...
      </div>
    );
  }

  if (isError) {
    return <ErrorComponent title={"Something went wrong"} text={error.message} isError={0} redirectTo="/" />;
  }

  const {
    data: {
      result: { total = [], currentPage = 1, pages = 0 }
    }
  } = auditUseQuery;

  const audits: Properties[] = total.map((audit: Properties) => ({
    ...audit,
    id: audit.id,
    type: audit.ApiAudit.type,
    path: audit.ApiAudit.path,
    description: audit.ApiAudit.description,
    createdBy: audit.createdBy
  }));

  if (audits.length < PAGE_SIZE) {
    fillEmptyCells(audits);
  }

  return (
    <div>
      <Row>
        <Card className="shadow">
          <CardHeader>
            <Filters
              onDateChange={onDateChange}
              handleOnActionChange={handleOnActionChange}
              handleClearFilter={handleClearFilter}
              action={filters.action}
              startDate={filters.startDate}
              endDate={filters.endDate}
            />
          </CardHeader>
          <CardBody>
            <Table
              componentLoading={isLoading && <LoadingComponent />}
              id="audit-table"
              schema={tableSchema}
              data={audits}
              striped
              isLoading
            />
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-center">
              <Pagination
                totalPages={pages}
                currentPage={currentPage}
                onChange={(event, page) => {
                  handleOnChangePagination(page);
                }}
              />
            </div>
          </CardFooter>
        </Card>
      </Row>
    </div>
  );
};

export default Audit;
