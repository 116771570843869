import styled from "styled-components";

export const ConsentStyledForm = styled.div`
  width: 90%;
  display: flex;
  align-items: center;

  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.29);
`;
