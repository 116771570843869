import Swal from "sweetalert2";
import { UseMutateFunction, useMutation } from "react-query";
import { createPurposeService } from "../services/createPurposeService";
import { useQueryApiError, useQueryApiSucess } from "../types";

type usePurposeUseQueryType = {
  mutate: UseMutateFunction<unknown, useQueryApiError, any, () => void>;
  isLoadingAction: boolean;
  isErrorAction: boolean;
  ErrorAction: useQueryApiError;
  isSuccessAction: boolean;
};

export const usePurposeMutation = (): usePurposeUseQueryType => {
  const {
    mutate,
    isLoading: isLoadingAction,
    isError: isErrorAction,
    error: ErrorAction,
    isSuccess: isSuccessAction
  } = useMutation<unknown, any, any, () => unknown>({
    mutationFn: ({ name, description, currentUser }) => {
      Swal.fire({
        title: "Please wait...",
        timer: 5000
      });
      Swal.showLoading();

      return createPurposeService({ name, description, currentUser });
    },
    onSuccess(data) {
      const { message } = data as useQueryApiSucess;
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message
      });
    },
    onError(error) {
      const { message } = error as useQueryApiError;
      Swal.fire({
        icon: "error",
        title: "Error",
        text: message
      });
    }
  });
  return { mutate, isLoadingAction, isErrorAction, ErrorAction, isSuccessAction };
};
