import { Dispatch, SetStateAction, useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { getDraftByPurposeResponse, useQueryApiError } from "../types";
import { getDraftsByPurposeService } from "../services/getDraftsByPurposeService";

type UseDraftParams = {
  purpose: string;
  country: string;
  business: string;
  token: string;
};

type UseDraftHookType = {
  consentConfigQuery: UseQueryResult<getDraftByPurposeResponse, useQueryApiError>;
  setFetched: Dispatch<SetStateAction<boolean>>;
};

export const useConsentConfig = ({ business, country, purpose, token }: UseDraftParams): UseDraftHookType => {
  const [fetched, setFetched] = useState(false);

  const keyCache = `draft-${business}-${country}-${purpose}`;

  const consentConfigQuery = useQuery<getDraftByPurposeResponse, useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getDraftsByPurposeService({ business, country, purpose, token });
      setFetched(false);
      return response;
    },
    { enabled: fetched, cacheTime: 500 }
  );

  return {
    consentConfigQuery,
    setFetched
  };
};
