import { Text } from "@jarvis-catalyst/ui-components";
import React from "react";
import { ContextConsent } from "../../types";

type ContextConsentProps = {
  contextConsent: ContextConsent;
};

const ContextConsentForm = ({ contextConsent }: ContextConsentProps): JSX.Element => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <Text className="">Advisor Identifier</Text>
          <input type="text" className="form-control" disabled value={contextConsent.advisorIdentifier} />
        </div>
        <div className="col">
          <Text className="">Advisor Name</Text>
          <input type="text" className="form-control" disabled value={contextConsent.advisorName} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Business Short Name</Text>
          <input type="text" className="form-control" disabled value={contextConsent.businessShortName} />
        </div>
        <div className="col">
          <Text className="">Channel</Text>
          <input type="text" className="form-control" disabled value={contextConsent.channel} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Commerce</Text>
          <input type="text" className="form-control" disabled value={contextConsent.commerce} />
        </div>
        <div className="col">
          <Text className="">Country Code</Text>
          <input type="text" className="form-control" disabled value={contextConsent.countryCode} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Date Time Channel</Text>
          <input type="text" className="form-control" disabled value={contextConsent.dateTimeChannel ?? ""} />
        </div>
        <div className="col">
          <Text className="">Geolocation</Text>
          <input type="text" className="form-control" disabled value={contextConsent.geoLocation} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Session Channel</Text>
          <input type="text" className="form-control" disabled value={contextConsent.sessionChannel ?? ""} />
        </div>
        <div className="col">
          <Text className="">User Name</Text>
          <input type="text" className="form-control" disabled value={contextConsent.userName} />
        </div>
      </div>
    </div>
  );
};

export default ContextConsentForm;
