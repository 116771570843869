import { PERMISSION } from "../constants";

const {
  CUSTOMER_ACCOUNT,
  CUSTOMER_IDENTITY,
  BLOCKLIST_DOCUMENT
} = PERMISSION;

export const validateUserOption = [
  {
    title: "Validate user",
    validPermissions: [CUSTOMER_IDENTITY, CUSTOMER_ACCOUNT]
  },
];

export const lockPasswordOption = [
  {
    title: "Lock password",
    validPermissions: [CUSTOMER_ACCOUNT]
  },
];

export const unlockPasswordOption = [
  {
    title: "Unlock password",
    validPermissions: [CUSTOMER_ACCOUNT]
  },
];

export const lockUnlockAccountOption = [
  {
    title: "Lock/Unlock account",
    validPermissions: [BLOCKLIST_DOCUMENT]  
  },
];

export const blocklistOptions = [
  {
    title: "Lock/Unlcok of document",
    validPermissions: [BLOCKLIST_DOCUMENT]
  },
  {
    title: "Validate document status",
    validPermissions: [BLOCKLIST_DOCUMENT]
  },
  {
    title: "Blocking history",
    validPermissions: [BLOCKLIST_DOCUMENT]
  }
];

export const userOptionList = [
  {
    title: "Bloqueo/Desbloqueo de cuenta",
    url: "/client/lock-unlock",
    enabled: true
  },
  {
    title: "Bloqueo/Desbloqueo de documento",
    url: "/client/lock-unlock-document",
    enabled: true
  },
  {
    title: "Validar estado de documento",
    url: "/client/search-validate-document",
    enabled: true
  },
  {
    title: "Historial de bloqueos",
    url: "/client/audit",
    enabled: true
  }
];