import React, { FormEvent, ReactElement, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  Row,
  Select,
  Text
} from "@jarvis-catalyst/ui-components";
import { useNavigate } from "react-router-dom";
import { LockActionLocationState, LockLocationState } from "../../types";
import { FormAlertGroup } from "../../styled-components/FormGroup";
import { hasMatchFIF } from "../../utils/sbifMatcher";
import { FormSelect } from "../../styled-components/FormSelect";
import { useValidateForm } from "../../hooks/useValidateForm";
import { useUtils } from "../../hooks/useUtils";
import { useServiceProviders } from "../../hooks/useServiceProviders";
import { serviceProvidersOptionsList } from "../../utils/listOptions";
import { FIFOptionList } from "../../config/constants";
import { validateIfHasPermissionToAccessView } from "../../utils/filterPermission";
import { lockPasswordOption } from '../../config/data/userOptions';
import { useUser } from "../../hooks/useUser";

const initSelectedValue = {
  value: "",
  label: ""
};

type Props = {
  action: LockActionLocationState;
  title: string;
};

const LockForm = ({ action, title }: Props): ReactElement => {
  const navigate = useNavigate();
  const { serviceProviderUseQuery } = useServiceProviders();
  const { error, loadingValidate, setForm, validateForm, form } = useValidateForm();
  const { setServiceProvider, setUserName } = useUtils();

  const [selectedValueProv, setSelectedValueProv] = useState(initSelectedValue);
  const [selectedValue, setSelectedValue] = useState(initSelectedValue);
  const [lockPassword] = useState(lockPasswordOption);

  const { permission } = useUser();

  const hasValidPermission = lockPassword.some(({ validPermissions }) => {
    return validateIfHasPermissionToAccessView({
      validPermissionsView: validPermissions,
      userPermission: permission,
      action: "update"
    });
  });

  const handleSubmitSearchCustomer = async (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm()) {
      return;
    }

    setServiceProvider(form.serviceProvider);
    hasMatchFIF(form.serviceProvider) ? setUserName(form.nroDocumento) : setUserName(form.email);

    navigate("/lockAction", {
      state: {
        form,
        action: action,
        title: title
      } as LockLocationState
    });
  };

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <Card className="shadow">
            <CardBody>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">{title}</h2>
                <hr />
                <form className="mb-1" onSubmit={handleSubmitSearchCustomer}>
                  <div className="">
                    <Text>Service Provider:</Text>
                    <Select
                      onInputChange={(value: string) => setForm({ ...form, serviceProvider: value })}
                      options={serviceProvidersOptionsList(serviceProviderUseQuery.data)}
                      onSelectedValue={setSelectedValueProv}
                      value={selectedValueProv}
                      placeholder="Select an option..."
                      textNotFound="-- Not Found --"
                    />
                  </div>
                  {!hasMatchFIF(form.serviceProvider) && form.serviceProvider && (
                    <div className="mb-3">
                      <Text className="">Email:</Text>
                      <Input
                        focus
                        placeholder={"email@example.com"}
                        value={form.email}
                        onChange={(e: FormEvent<HTMLInputElement>) => {
                          setForm({ ...form, email: e.currentTarget.value });
                        }}
                      />
                    </div>
                  )}
                  {hasMatchFIF(form.serviceProvider) && (
                    <>
                      <FormSelect>
                        <Text>Tipo Documento:</Text>
                        <Select
                          onInputChange={(value: string) => setForm({ ...form, tipoDocumento: value })}
                          options={FIFOptionList}
                          onSelectedValue={setSelectedValue}
                          value={selectedValue}
                          placeholder="Select an option..."
                          textNotFound="-- Not Found --"
                        />
                      </FormSelect>
                      <div className="mb-3">
                        <Text>Document Number:</Text>
                        <Input
                          placeholder={"12345678-9 or 123456789"}
                          value={form.nroDocumento}
                          onChange={(e: FormEvent<HTMLInputElement>) => {
                            setForm({ ...form, nroDocumento: e.currentTarget.value });
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div className="d-grid">
                    <Button color="primary" className="mt-2 text-center" disabled={!hasValidPermission}>
                      "Check User"
                    </Button>
                    <Button
                      disabled={loadingValidate}
                      color="bordered"
                      type="button"
                      className="mt-2 text-center"
                      onClick={() => navigate("/")}
                    >
                      Back
                    </Button>
                  </div>
                </form>
              </div>
            </CardBody>
            {error.message && (
              <CardFooter>
                {error.message && (
                  <FormAlertGroup className="text-center">
                    <Alert color="danger">{error.message}</Alert>
                  </FormAlertGroup>
                )}
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LockForm;
