import { Button, ButtonGroup, CodeVisualizer, ModalBox, Text } from "@jarvis-catalyst/ui-components";
import React, { ReactElement } from "react";
import { useAuditPropertyById } from "../../hooks/useAuditProperty";
import Collapse from "../Collapse";
import LoadingComponent from "../Loading";

type Props = {
  size?: "md" | "lg";
  title: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  propertyId: string;
};

const PropertyDetailModal = ({ isOpen, setIsOpen, title, size = "md", propertyId }: Props): ReactElement => {
  const { dataFetched, isLoading } = useAuditPropertyById({ propertyId });

  return (
    <div>
      <>
        <ModalBox
          size={size}
          isOpen={isOpen}
          onBackgroundClose={() => setIsOpen(false)}
          children={
            <>
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <>
                  <div>
                    <div className="row">
                      <div className="col">
                        <Text className="">TraceId:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={dataFetched?.traceId ?? ""}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <Text className="">Creado Por:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={dataFetched?.createdBy ?? ""}
                        />
                      </div>

                      <div className="col">
                        <Text className="">Fecha de creación:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={dataFetched?.createdAt ?? ""}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="row">
                      <div className="col">
                        <Text className="">Versión del servicio:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={dataFetched?.ApiAudit.version ?? ""}
                        />
                      </div>

                      <div className="col">
                        <Text className="">Descripción:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={dataFetched?.ApiAudit.description ?? ""}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Text className="">Ruta:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={dataFetched?.ApiAudit.path ?? ""}
                        />
                      </div>

                      <div className="col">
                        <Text className="">Método:</Text>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={dataFetched?.ApiAudit.type ?? ""}
                        />
                      </div>
                    </div>
                    <hr />
                    <Collapse title="Ver solicitud realizada">
                      <div className="row">
                        <div className="col">
                          <CodeVisualizer buttonCopy children={dataFetched?.ExtraData.extraAtributtes} />
                        </div>
                      </div>
                    </Collapse>
                    <hr />
                    <Collapse title="Ver respuesta de los servicios">
                      <div className="row">
                        <div className="col">
                          <CodeVisualizer buttonCopy children={dataFetched?.ApiResponse.body} />
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
              )}
            </>
          }
          components={{
            Header: () => (
              <>
                <h3>{title}</h3>
              </>
            ),
            Footer: () => (
              <>
                <ButtonGroup>
                  <Button onClick={() => setIsOpen(false)}>Cerrar</Button>
                </ButtonGroup>
              </>
            )
          }}
        />
      </>
    </div>
  );
};

export default PropertyDetailModal;
