import { Text } from "@jarvis-catalyst/ui-components";
import React from "react";
import { EvidenceConsent } from "../../types";

type Props = {
  evidenceConsent: EvidenceConsent;
};

const EvidenceConsentForm = ({ evidenceConsent }: Props): JSX.Element => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <Text className="">App Identifier</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.appIdentifier} />
        </div>
        <div className="col">
          <Text className="">Customer Device</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.customerDevice} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Ip Device</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.externalLocation} />
        </div>
        <div className="col">
          <Text className="">Mac Address Device</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.ipDevice} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Server App Identifier</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.macAddressDevice} />
        </div>
        <div className="col">
          <Text className="">Server App Identifier</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.serverAppIdentifier} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">System Device</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.systemDevice} />
        </div>
        <div className="col">
          <Text className="">System Device Version</Text>
          <input
            type="text"
            className="form-control"
            disabled
            value={evidenceConsent.systemDeviceVersion ?? ""}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Device Identifier</Text>
          <input type="text" className="form-control" disabled value={evidenceConsent.deviceIdentifier} />
        </div>
      </div>
    </div>
  );
};

export default EvidenceConsentForm;
