import { UseMutationResult, useMutation } from "react-query";
import { getTemplateByPurpose } from "../services/getTemplateByPurpose";
import { useQueryApiError, GetTemplateByPurposeResult } from "../types";
import { Dispatch, SetStateAction, useState } from "react";
import Swal from "sweetalert2";

type MutationParams = {
  businessUnit: string;
  country: string;
  purpose: string;
  token: string;
};

type TemplateHookType = {
  useTemplateQuery: UseMutationResult<GetTemplateByPurposeResult, useQueryApiError, MutationParams, unknown>;
  templatesFetched: GetTemplateByPurposeResult | undefined;
  setEnabledCreate: Dispatch<SetStateAction<boolean>>;
  setEnabledEdit: Dispatch<SetStateAction<boolean>>;
  enabledCreate: boolean;
  enabledEdit: boolean;
  setTemplatesFetched: Dispatch<SetStateAction<GetTemplateByPurposeResult | undefined>>;
};

export const useTemplateByPurpose = (): TemplateHookType => {
  const [enabledCreate, setEnabledCreate] = useState<boolean>(false);
  const [enabledEdit, setEnabledEdit] = useState<boolean>(false);
  const [templatesFetched, setTemplatesFetched] = useState<GetTemplateByPurposeResult>();

  const useTemplateQuery = useMutation<GetTemplateByPurposeResult, useQueryApiError, MutationParams, unknown>(
    {
      mutationFn: async ({ businessUnit, country, purpose, token }: MutationParams) => {
        return await getTemplateByPurpose({ businessUnit, country, purpose, token });
      },
      onMutate: () => {
        Swal.fire({
          title: "...",
          html: "Please wait...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
      },
      onSuccess: ({ data }) => {
        Swal.close();
        if (data.length == 0) setEnabledCreate(true);
        if (data.length > 0) setEnabledEdit(true);
      },
      onError: (error) => {
        Swal.close();
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Ok"
        });
      }
    }
  );

  return {
    setEnabledCreate,
    setEnabledEdit,
    enabledCreate,
    enabledEdit,
    useTemplateQuery,
    templatesFetched,
    setTemplatesFetched
  };
};
