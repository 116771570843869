import { PERMISSION } from "../constants";

const { CONSENT_MANTAINER } = PERMISSION;

export const createPurposeOption = [
  {
    title: "Create purpose",
    validPermissions: [CONSENT_MANTAINER]
  }
];

export const createConsentOption = [
  {
    title: "Create consent",
    validPermissions: [CONSENT_MANTAINER]
  }
];

export const approveConsentsOption = [
  {
    title: "Approve consents",
    validPermissions: [CONSENT_MANTAINER]
  }
];

export const consentOptionList = [
  {
    title: "Crear Punto de Captura",
    url: "/consent/create-purpose",
    enabled: true
  },
  {
    title: "Crear Consentimiento",
    url: "/consent/search-consent",
    enabled: true
  },
  {
    title: "Aprobar Consentimientos",
    url: "/consent/draft",
    enabled: true
  }
];
