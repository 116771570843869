import React, { ReactElement, FormEvent, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  Label,
  Row,
  Spinner
} from "@jarvis-catalyst/ui-components";
import { useNavigate } from "react-router-dom";
import { ListForm } from "../../styled-components/ListOptionsCard";
import { useUser } from "../../hooks/useUser";
import { version } from "../../../package.json";
import { consentOptionList } from "../../config/data/consentOptions";

const ConsentOption = (): ReactElement => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [options, setOptions] = useState(consentOptionList);

  const filterOptions = async (e: FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    setOptions(
      consentOptionList.filter(
        (value) => value.title.toLowerCase().indexOf(e.currentTarget.value.toLowerCase()) != -1
      )
    );
  };

  return (
    <Row className="d-flex justify-content-center align-items-center">
      {user.email ? (
        <Col md={8} lg={6} xs={12}>
          <Card className="shadow">
            <CardBody>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">Opciones de Consentimiento</h2>
                <Label
                  className="d-flex justify-content-center align-items-center fw-bold mb-2 text-center"
                  children={"v" + version}
                ></Label>
                <hr />
                <div className="d-grid mb-2">
                  <Input
                    onChange={(e: FormEvent<HTMLInputElement>) => filterOptions(e)}
                    value={search}
                    placeholder="Buscar una opción..."
                  />
                </div>
                <hr />
                <ListForm className="mb-1" size={options.length}>
                  {options.map(({ enabled, title, url }, index) => {
                    if (enabled) {
                      return (
                        <div className="d-grid mb-2" key={index}>
                          <Button
                            className="text-center text-uppercase"
                            color="primary"
                            type="submit"
                            onClick={() => navigate(`${url}`)}
                          >
                            {title}
                          </Button>
                        </div>
                      );
                    }
                  })}
                </ListForm>
              </div>
            </CardBody>
            <hr />
            <CardFooter>
              <div className="d-grid">
                <Button
                  className="text-center text-uppercase"
                  color="bordered"
                  type="button"
                  onClick={() => navigate("/")}
                >
                  Volver
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      ) : (
        <Spinner position="absolute" color="blue" />
      )}
    </Row>
  );
};

export default ConsentOption;
