import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import { useUser } from "./useUser";
import { ClaimsResponseService, ClaimsUser, useQueryApiError } from "../types";
import { getClaimsService } from "../services/getClaimsService";

type useClaimsHookType = {
  claimsUseQuery: UseQueryResult<ClaimsResponseService, useQueryApiError>;
  filterAccounts?: ClaimsUser[];
  invalidateKey: () => void;
};

type useQueryClaimsParams = {
  serviceProvider: string;
  email?: string;
  documentNumber?: string;
};

export const useQueryClaims = ({
  serviceProvider,
  documentNumber,
  email
}: useQueryClaimsParams): useClaimsHookType => {
  const { user } = useUser();
  const queryClient = useQueryClient();

  const key = `claims-${documentNumber}`;

  const claimsUseQuery = useQuery<ClaimsResponseService, useQueryApiError>(
    [key],
    async () => {
      const response = await getClaimsService({
        token: user.token,
        serviceProvider,
        email,
        documentNumber,
        requestedBy: user.email
      });
      return response;
    },
    { cacheTime: 500 }
  );

  const filterAccounts = claimsUseQuery.data?.data.flatMap((item) =>
    item.users && Array.isArray(item.users) ? item.users : []
  );

  const invalidateKey = async () => {
    await queryClient.invalidateQueries({ queryKey: [key] });
    claimsUseQuery.refetch();
  };

  return {
    claimsUseQuery,
    filterAccounts,
    invalidateKey
  };
};
