import { Dispatch, SetStateAction, useState } from "react";
import { FormType } from "../types";
import { initialForm } from "./useConsent";
import { hasMatchFIF } from "../utils/sbifMatcher";

type ErrorType = {
  message: string;
};

type ValidateFormHookType = {
  form: FormType;
  setForm: Dispatch<SetStateAction<FormType>>;
  validateForm: () => boolean;
  error: ErrorType;
  setLoadingValidate: Dispatch<SetStateAction<boolean>>;
  loadingValidate: boolean;
};

export const useValidateForm = (): ValidateFormHookType => {
  const [form, setForm] = useState<FormType>(initialForm);
  const [error, setError] = useState<ErrorType>({ message: "" });
  const [loadingValidate, setLoadingValidate] = useState(false);

  const validateForm = (): boolean => {
    let message = "";
    setLoadingValidate(true);

    const { email, serviceProvider, nroDocumento, tipoDocumento } = form;

    if (!nroDocumento) {
      if (email === "") {
        message = "Email is required";
      }
    }

    if (hasMatchFIF(serviceProvider)) {
      if (nroDocumento === "") {
        message = "Document Number is required";
      }

      if (tipoDocumento === "") {
        message = "Document Type is required";
      }
    }

    if (serviceProvider === "") {
      message = "Service Provider is required";
    }

    setLoadingValidate(false);
    setError({ message });
    return message ? true : false;
  };

  return { form, setForm, validateForm, error, setLoadingValidate, loadingValidate };
};
