import { Text } from "@jarvis-catalyst/ui-components";
import React from "react";
import { Purpose } from "../../types";

type Props = {
  purpose: Purpose;
};

const PurposeTab = ({ purpose }: Props): JSX.Element => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <Text className="">Purpose ID</Text>
          <input type="text" className="form-control" disabled value={purpose.purposeId} />
        </div>

        <div className="col">
          <Text className="">Purpose Name</Text>
          <input type="text" className="form-control" disabled value={purpose.purposeName} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Purpose Description</Text>
          <input type="text" className="form-control" disabled value={purpose.purposeDescription} />
        </div>
      </div>
    </div>
  );
};

export default PurposeTab;
