import React from "react";
import ConsentBaseForm from "../Form/consentBaseForm";
import TemplateTab from "./TemplateTab";
import PurposeTab from "./PurposeTab";
import { ConsentResponse } from "../../types";
import { Col, Tab } from "@jarvis-catalyst/ui-components";
import ContextConsentForm from "../Form/contextConsentForm";
import EvidenceConsentForm from "../Form/evidenceConsentForm";

type ConsentTabProps = {
  consent: ConsentResponse;
};

const PrimaryConsentTab = ({ consent }: ConsentTabProps): JSX.Element => {
  return (
    <Col md={12} lg={12} xs={12} sm={12}>
      <h2 className="fw-bold mb-2 text-uppercase text-center">Consent N° {consent.consentId}</h2>
      <ConsentBaseForm consent={consent} />
      <hr />
      <Tab
        pages={[
          {
            title: "Template",
            key: "template",
            body: <TemplateTab template={consent.template} />
          },
          {
            title: "Template Purpose",
            key: "templatePurpose",
            body: <PurposeTab purpose={consent.template.purpose} />
          }
        ]}
      />
      <hr />
      <h4 className="text-center">
        <strong>Context Consent</strong>
      </h4>
      <ContextConsentForm contextConsent={consent.contextConsent} />
      <hr />
      <h4 className="text-center">
        <strong>Evidence Consent</strong>
      </h4>
      <EvidenceConsentForm evidenceConsent={consent.evidenceConsent} />
    </Col>
  );
};

export default PrimaryConsentTab;
