import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import BackendHealthCheck from "../pages/BackendHealthCheck";
import DeleteAccount from "../pages/Delete/DeleteAccount";
import DeleteUser from "../pages/Delete/DeleteUser";
import NotFound from "../pages/NotFound";
import Option from "../pages/Option";
import PermissionDenied from "../pages/PermissionDenied";
import PrivateRoutes from "./privateRoutes";
import ValidateConsent from "../pages/ValidateConsent";
import ProfileConsent from "../pages/ProfileConsent";
import DeleteUserAccount from "../pages/Delete/DeleteAction";
import LockPassword from "../pages/Lock/LockPassword";
import LockAction from "../pages/Lock/LockAction";
import UnlockAction from "../pages/Unlock/UnlockAction";
import UnlockPassword from "../pages/Unlock/UnlockPassword";
import ConsentRoutes from "./consentRoutes";
import ClientUnlock from "../pages/Client/ClientUnlock";
import ClientUnlockAction from "../pages/Client/ClientUnlockAction";
import Audit from "../pages/Audit";
import UserOptions from "../pages/UserOptions";
import ValidateDocumentAction from "../pages/ValidateDocument/validate";
import FormValidateDocument from "../pages/ValidateDocument";
import CreateDocumentLockUnlock from "../pages/Document";
import AuditDocumentAndAccount from "../pages/AuditDocument";

const MainRoutes = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/ciam-tools" />} />
        <Route path="/">
          <Route path="/ciam-tools" element={<Option />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/delete-user" element={<DeleteUser />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/delete" element={<DeleteUserAccount />} />
            <Route path="/validateConsent" element={<ValidateConsent />} />
            <Route path="/validateConsent/profileConsent" element={<ProfileConsent />} />
            <Route path="/lockPassword" element={<LockPassword />} />
            <Route path="/lockAction" element={<LockAction />} />
            <Route path="/unlockAction" element={<UnlockAction />} />
            <Route path="/unlockPassword" element={<UnlockPassword />} />
            <Route path="/client/lock-unlock" element={<ClientUnlock />} />
            <Route path="/clientUnlockAction" element={<ClientUnlockAction />} />
            <Route path="/client/lock-unlock-document" element={<CreateDocumentLockUnlock />} />
            <Route path="/client/search-validate-document" element={<FormValidateDocument />} />
            <Route path="/client/validate-document" element={<ValidateDocumentAction />} />
            <Route path="/client/audit" element={<AuditDocumentAndAccount />} />
            <Route path="/user-management" element={<UserOptions />} />
            <Route path="/backend-apis" element={<BackendHealthCheck />} />
            <Route path="/audit" element={<Audit />} />
            {ConsentRoutes()}
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/access-denied" element={<PermissionDenied />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
