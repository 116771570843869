import axiosInstance from "../config/axiosIntance";
import { ResponseGetAuditPropertyById } from "../types";

type Params = {
  propertyId: string;
  token: string;
};

export const getAuditPropertyByIdService = async ({
  propertyId,
  token
}: Params): Promise<ResponseGetAuditPropertyById> => {
  const dataFetched = await axiosInstance.get<ResponseGetAuditPropertyById>(`/audits/property/${propertyId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return dataFetched.data;
};
