import axiosInstance from "../config/axiosIntance";
import { GetTemplateByPurposeResult } from "../types";

type getTemplateByPurposeParams = {
  purpose: string;
  businessUnit: string;
  country: string;
  token: string;
};

export const getTemplateByPurpose = async ({
  businessUnit,
  country,
  purpose,
  token
}: getTemplateByPurposeParams): Promise<GetTemplateByPurposeResult> => {
  const dataFetched = await axiosInstance.post<GetTemplateByPurposeResult>(
    `/consent/getTemplateByPurpose`,
    {
      businessUnit,
      country,
      purpose
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  return dataFetched.data;
};
