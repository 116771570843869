import React, { ReactElement, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserContext from "../context/UserContext";
import { validateAction } from "../utils/filterPermission";
import { jarvisUserKey } from "../config/constants";

const PrivateRoutes = (): ReactElement => {
  const { permission, user } = useContext(UserContext);
  const hasPermission = validateAction(permission, jarvisUserKey, "read");
  const hasToken = user.token ? true : false;

  return hasPermission && hasToken ? <Outlet /> : <Navigate to="/access-denied" />;
};

export default PrivateRoutes;
