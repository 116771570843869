import axiosInstance from "../config/axiosIntance";
import { getAllPurposeServiceResponse } from "../types";

type getAllPurposeServiceParams = {
  token: string;
};

export const getAllPurposeService = async ({
  token
}: getAllPurposeServiceParams): Promise<getAllPurposeServiceResponse> => {
  const dataFetched = await axiosInstance.get<getAllPurposeServiceResponse>("/utils/getAllPurpose", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return dataFetched.data;
};
