import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./App";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const queryClient = new QueryClient();

const isReactQueryDevtoolsEnabled = process.env.REACT_APP_REACT_QUERY_DEVTOOLS === "true" ? true : false;

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      {isReactQueryDevtoolsEnabled && <ReactQueryDevtools />}
    </QueryClientProvider>
  </React.StrictMode>,
  rootElement
);
