import axiosInstance from "../config/axiosIntance";
import { BFF_BASE_URL } from "../config/constants";

import { NewVersionConsentConfigType } from "../types/newVersionConsentConfig";
import { ResponseUpdateTemplateService } from "../types";

type UpdateTemplateParams = {
  country: string;
  business: string;
  purpose: string;
  templates: NewVersionConsentConfigType[];
  token: string;
  version: string;
};

export const updateTemplateService = async ({
  country,
  business,
  purpose,
  templates,
  token,
  version
}: UpdateTemplateParams): Promise<ResponseUpdateTemplateService> => {
  const responseFetched = await axiosInstance.post(
    `${BFF_BASE_URL}/ciam-tools-bff/consent-config/draft/newVersion`,
    {
      country,
      business,
      purpose,
      templates,
      version
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  );
  return responseFetched.data;
};
