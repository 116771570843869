import { ResponseGetConsentProfileService, UserType } from "../types";
import axiosInstance from "../config/axiosIntance";

type ProfileServiceParams = {
  email: string;
  serviceProvider: string;
  nroDocumento?: string;
  tipoDocumento?: string;
  currentUser: UserType;
};

export const getConsentService = async ({
  email,
  serviceProvider,
  nroDocumento,
  tipoDocumento,
  currentUser
}: ProfileServiceParams): Promise<ResponseGetConsentProfileService> => {
  const responseFetched = await axiosInstance.post(
    `/consent/customer/`,
    {
      email,
      serviceProvider,
      documentNumber: nroDocumento,
      documentType: tipoDocumento
    },
    {
      headers: {
        Authorization: `Bearer ${currentUser.token}`
      }
    }
  );

  const { data } = responseFetched.data;

  return data;
};
