import { Button, ButtonGroup, ModalBox } from "@jarvis-catalyst/ui-components";
import React, { ReactElement } from "react";
import Editor from "../Editor";

type ModalEditProps = {
  setValue: (value: string) => void;
  isReadOnly?: boolean;
  text: string;
  title: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleOnDestroy?: () => void;
};

const ModalEditTextWithEditor = ({
  text,
  title,
  isOpen,
  setIsOpen,
  isReadOnly = false,
  setValue,
  handleOnDestroy
}: ModalEditProps): ReactElement => {
  return (
    <ModalBox
      size="lg"
      isOpen={isOpen}
      onBackgroundClose={() => setIsOpen(false)}
      children={
        <>
          <Editor data={text} isReadOnly={isReadOnly} setValue={setValue} handleOnDestroy={handleOnDestroy}/>
        </>
      }
      components={{
        Header: () => (
          <>
            <h4>{title}</h4>
          </>
        ),
        Footer: () => (
          <>
            <ButtonGroup>
              {!isReadOnly && (
                <Button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Save
                </Button>
              )}
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </ButtonGroup>
          </>
        )
      }}
    />
  );
};

export default ModalEditTextWithEditor;
