import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import { approveDraftService } from "../services/approveDraftService";
import { ResponseApproveDraftService, useQueryApiError } from "../types";

type MutationParams = {
  business?: string;
  country?: string;
  token?: string;
  draftId?: string;
};

type ApproveDraftHookType = {
  useApproveDraftQuery: UseMutationResult<
    ResponseApproveDraftService,
    useQueryApiError,
    MutationParams,
    unknown
  >;
};

export const useApproveDraft = (): ApproveDraftHookType => {
  const queryClient = useQueryClient();

  const useApproveDraftQuery = useMutation<
    ResponseApproveDraftService,
    useQueryApiError,
    MutationParams,
    unknown
  >({
    mutationFn: async ({ business, country, draftId, token }: MutationParams) => {
      return await approveDraftService({ business, country, draftId, token });
    },
    onMutate: () => {
      Swal.fire({
        title: "...",
        html: "Please wait, we are approving templates.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onSuccess: () => {
      Swal.fire({
        title: "Success!",
        text: "The templates was approved successfully.",
        icon: "success",
        width: "650px",
        confirmButtonText: "Ok",
        didClose() {
          queryClient.invalidateQueries({ queryKey: ["templatesDraft"] });
        }
      });
    },
    onError: (error) => {
      Swal.close();
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "Ok"
      });
    }
  });

  return {
    useApproveDraftQuery
  };
};
