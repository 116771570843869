import React from "react";
import { RelatedConsents as RelatedType } from "../../types";
import TemplateTab from "./TemplateTab";
import ConsentBaseForm from "../Form/consentBaseForm";
import { Col, Tab } from "@jarvis-catalyst/ui-components";
import PurposeTab from "./PurposeTab";

type Props = {
  relatedConsents: RelatedType[];
};

const RelatedConsents = ({ relatedConsents }: Props): JSX.Element => {
  return (
    <Col md={12} lg={12} xs={12} sm={12}>
      {relatedConsents?.map((relatedConsent) => (
        <div key={relatedConsent.consentId}>
          <h2 className="fw-bold mb-2 text-uppercase text-center">Consent N° {relatedConsent.consentId}</h2>
          <ConsentBaseForm consent={relatedConsent} />
          <hr />
          <Tab
            pages={[
              {
                title: "Template",
                key: `related-consent-${relatedConsent.consentId}`,
                body: <TemplateTab template={relatedConsent.template} />
              },
              {
                title: "Template Purpose",
                key: `related-consent-purpose-${relatedConsent.consentId}`,
                body: <PurposeTab purpose={relatedConsent.template.purpose} />
              }
            ]}
          />
          <hr />
        </div>
      ))}
    </Col>
  );
};

export default RelatedConsents;
