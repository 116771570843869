import { UseQueryResult, useQuery } from "react-query";
import { ServiceProvider, useQueryApiError } from "../types";
import { getAllServiceProviderService } from "../services/getAllServiceProviderService";
import { useUser } from "./useUser";

const keyCache = `serviceProviders`;

type ServiceProviderHookType = {
  serviceProviderUseQuery: UseQueryResult<ServiceProvider[], useQueryApiError>;
};

export const useServiceProviders = (): ServiceProviderHookType => {
  const { user } = useUser();
  const serviceProviderUseQuery = useQuery<ServiceProvider[], useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getAllServiceProviderService({ token: user.token });

      return response.data;
    },
    { cacheTime: 5000 }
  );

  return {
    serviceProviderUseQuery
  };
};
