import React, { ReactElement, useContext, useEffect } from "react";
import Consent from "./consent";
import ConsentContext from "../../../context/ConsentContext";
import { useNavigate } from "react-router";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Text
} from "@jarvis-catalyst/ui-components";
import { countElementsInGroup } from "../../../utils/countElementsByGroup";
import { CardBodyCustom } from "../../../styled-components/Card";
import { ConsentStyledForm } from "../../../styled-components/ConsentForm";
import { FormAlertGroup } from "../../../styled-components/FormGroup";
import { useForm } from "react-hook-form";
import { useCreateTemplate } from "../../../hooks/useCreateTemplate";
import { useUser } from "../../../hooks/useUser";
import { invertedBusinessMap } from "../../../utils/listOptions";

const CreateConsent = (): ReactElement => {
  let acum = 0;
  const navigate = useNavigate();
  const { user } = useUser();
  const {
    searchConsentForm,
    consentChildren,
    addElementToGroup,
    addGroupAndElement,
    removeElementByGroup,
    groups
  } = useContext(ConsentContext);
  const { business, country, purpose } = searchConsentForm;

  const useFormController = useForm();
  const { mutate } = useCreateTemplate();

  const onClickSaveTemplate = async () => {
    await mutate({
      business,
      country,
      purpose,
      templates: consentChildren,
      token: user.token
    });
  };

  useEffect(() => {
    if (!business || !country || !purpose) {
      navigate("/consent");
    }
  }, [location]);

  return (
    <div>
      <Row className="d-flex justify-content-center align-items-center mb-5">
        <Col md={10} lg={10} xs={10} sm={10}>
          <Card className="shadow">
            <h2 className="fw-bold mb-2 text-uppercase text-center">Crear Consentimiento</h2>
            <hr />
            <CardBody>
              <div className="row">
                <div className="col mb-2">
                  <Text className="">País</Text>
                  <Input value={country} onChange={() => null} disabled />
                </div>

                <div className="col mb-2">
                  <Text className="">Negocio</Text>
                  <Input value={invertedBusinessMap[business] ?? business} onChange={() => null} disabled />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Text className="">Punto de Captura</Text>
                  <Input value={purpose} onChange={() => null} disabled />
                </div>
                <div className="col"></div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {consentChildren.length === 0 && (
        <>
          <Row className="d-flex justify-content-center align-items-center mb-5">
            <Col md={10} lg={10} xs={10} sm={10}>
              <Card className="shadow">
                <CardBody>
                  <div className="d-flex justify-content-center align-items-center">
                    <FormAlertGroup className="text-center">
                      <Alert color="info">
                        <strong>INFO:</strong> El Negocio, País y Experiencia no tienen templates disponibles
                        para agregarse. Para proceder, debe seleccionar el botón 'Agregar Nuevo Template'
                      </Alert>
                      <Button
                        color="success"
                        className="text-center mt-2"
                        onClick={() => addGroupAndElement(groups.length)}
                      >
                        Agregar Nuevo Template
                      </Button>
                    </FormAlertGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}

      {consentChildren.length > 0 && (
        <>
          <Row className="d-flex justify-content-center align-items-center mb-5">
            <Col md={10} lg={10} xs={10} sm={10}>
              <form>
                {groups.map((valueGroup) => {
                  return (
                    <div key={valueGroup}>
                      <Card className="shadow mb-3">
                        <CardHeader>
                          <div className="d-flex">
                            <Text>CheckBox:</Text>
                            <Text>{valueGroup}</Text>
                          </div>
                        </CardHeader>
                        {consentChildren.map((item, index) => {
                          if (valueGroup == item.group) {
                            acum += 1;
                            item.position = acum;
                            return (
                              <div key={index} className="mb-3">
                                <CardBodyCustom>
                                  <ConsentStyledForm className="row">
                                    <Consent
                                      consent={item}
                                      position={acum}
                                      key={acum}
                                      useStateForm={useFormController}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      {item.editable && (
                                        <Button
                                          type="button"
                                          className="mb-3"
                                          color="danger"
                                          onClick={() => removeElementByGroup(item.position)}
                                        >
                                          Eliminar Template
                                        </Button>
                                      )}
                                    </div>
                                  </ConsentStyledForm>
                                </CardBodyCustom>
                              </div>
                            );
                          }
                        })}
                        <div className="d-flex justify-content-end">
                          {countElementsInGroup(consentChildren, Number(valueGroup)) < 4 && (
                            <Button type="button" onClick={() => addElementToGroup(Number(valueGroup))}>
                              Agregar Template
                            </Button>
                          )}
                        </div>
                      </Card>
                    </div>
                  );
                })}
                <div>
                  <Button type="button" className="mr-2" onClick={() => addGroupAndElement(groups.length)}>
                    Agregar CheckBox
                  </Button>
                </div>
                <div className="d-grid mt-2">
                  <Button
                    className="text-center text-uppercase mt-2"
                    type="button"
                    color="success"
                    onClick={useFormController.handleSubmit(onClickSaveTemplate)}
                  >
                    Guardar Templates
                  </Button>
                  <Button
                    className="text-center text-uppercase mt-2"
                    color="bordered"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancelar
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CreateConsent;
