import axiosInstance from "../config/axiosIntance";
import { getDraftApprovedByPurposeNameResponse } from "../types";

type Params = {
  draftName: string;
  purposeName: string;
  country: string;
  business: string;
  token: string;
};

export const getDraftsApprovedByPurposeName = async ({
  business,
  country,
  purposeName,
  draftName,
  token
}: Params): Promise<getDraftApprovedByPurposeNameResponse> => {
  const dataFetched = await axiosInstance.get<getDraftApprovedByPurposeNameResponse>(
    `/consent-config/consentConfigByNameAndPurposeName/${draftName}/${purposeName}`,
    {
      headers: {
        country,
        business,
        Authorization: `Bearer ${token}`
      }
    }
  );
  return dataFetched.data;
};
