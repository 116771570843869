import { UseQueryResult, useQuery } from "react-query";
import { getDocumentStatusService } from "../services/getDocumentStatusService";
import { useUser } from "./useUser";
import { ResponseUnlockLockService, useQueryApiError } from "../types";

type ParamsUseQueryGetDocumentStatus = {
  serviceProvider: string;
  documentNumber?: string;
};

type useQueryGetDocumentStatusHookType = {
  documentNumberUseQuery: UseQueryResult<ResponseUnlockLockService, useQueryApiError>;
};

export const useQueryGetDocumentStatus = ({
  documentNumber,
  serviceProvider
}: ParamsUseQueryGetDocumentStatus): useQueryGetDocumentStatusHookType => {
  const { user } = useUser();
  const documentNumberUseQuery = useQuery<ResponseUnlockLockService, useQueryApiError>(
    ["getDocumentStatus", documentNumber, serviceProvider],
    async () => {
      const response = await getDocumentStatusService({ documentNumber, serviceProvider, token: user.token });
      return response;
    },
    { cacheTime: 5000 }
  );

  return { documentNumberUseQuery };
};
