import axiosInstance from "../config/axiosIntance";
import { ResponseGetServiceProviders } from "../types";

type ServiceProviderParams = {
  token?: string;
};

export const getAllServiceProviderService = async ({
  token
}: ServiceProviderParams): Promise<ResponseGetServiceProviders> => {
  const dataFetched = await axiosInstance.get("/service-provider/info", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return dataFetched.data;
};
