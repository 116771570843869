import axiosInstance from "../config/axiosIntance";
import { GetLegalTextResult } from "../types";

type getTemplateByPurposeParams = {
  templateId?: string;
  businessUnit: string;
  country: string;
  token: string;
};

export const getLegalTextServiceByTemplateId = async ({
  businessUnit,
  country,
  templateId,
  token
}: getTemplateByPurposeParams): Promise<GetLegalTextResult> => {
  const dataFetched = await axiosInstance.get<GetLegalTextResult>(`/utils/getLegalText`, {
    params: { business: businessUnit, country, templateId },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return dataFetched.data;
};
