import React, { ReactElement } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  ModalBox,
  Row,
  Text
} from "@jarvis-catalyst/ui-components";
import { CardBodyCustom } from "../../styled-components/Card";
import { ConsentStyledForm } from "../../styled-components/ConsentForm";
import TemplateValidateForm from "../Form/templateValidateForm";
import { DraftsInProgressDataType } from "../../types";
import { useApproveDraft } from "../../hooks/useApproveDrafts";
import { useUser } from "../../hooks/useUser";
import { invertedBusinessMap } from "../../utils/listOptions";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isReadOnly?: boolean;
  title: string;
  data?: DraftsInProgressDataType;
};

const ListTemplatesModal = ({ isOpen, setIsOpen, title, data }: Props): ReactElement => {
  const { user } = useUser();
  const { useApproveDraftQuery } = useApproveDraft();

  const { mutate } = useApproveDraftQuery;


  const getTemplates = () => {
    const parseTemplatesGroupAndPosition = data?.related_templates.map((template) => {
      return {
        ...template,
        group:  Number(template.group),
        position: Number(template.position)
      };
    }, []);

    const sortedTemplatesByGroupAndPosition = parseTemplatesGroupAndPosition?.sort(
      (a, b) => a.group - b.group || a.position - b.position
    );
    return sortedTemplatesByGroupAndPosition;
  }

  const getGroups = () => {
    return Array.from(
      new Set(
        getTemplates()?.map(function (template) {
          return template.group;
        }, [])
      )
    );
  }

  return (
    <>
      <ModalBox
        size="lg"
        isOpen={isOpen}
        onBackgroundClose={() => setIsOpen(false)}
        children={
          <>
            <Row className="d-flex justify-content-center align-items-center mb-5">
              <Col md={10} lg={10} xs={10} sm={10}>
                <Card className="shadow">
                  <h2 className="fw-bold mb-2 text-uppercase text-center">
                    Punto de Captura y Consentimientos
                  </h2>
                  <hr />
                  <CardBody>
                    <div className="row">
                      <div className="col mb-2">
                        <Text className="">País</Text>
                        <Input value={data?.country} onChange={() => null} disabled />
                      </div>

                      <div className="col mb-2">
                        <Text className="">Negocio</Text>
                        <Input
                          value={
                            data && invertedBusinessMap[data.business]
                              ? invertedBusinessMap[data.business]
                              : data && data.business
                          }
                          onChange={() => null}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Text className="">Punto de Captura</Text>
                        <Input value={data?.purpose.purposeName} onChange={() => null} disabled />
                      </div>
                      <div className="col"></div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center mb-5">
              <Col md={10} lg={10} xs={10} sm={10}>
                {getGroups().map((valueGroup) => {
                  return (
                    <Card className="shadow mb-3" key={valueGroup}>
                      <CardHeader>
                        <div className="d-flex">
                          <Text>CheckBox:</Text>
                          <Text>{valueGroup}</Text>
                        </div>
                      </CardHeader>
                      {getTemplates()?.map((item, index) => {
                        if (valueGroup == item.group) {
                          return (
                            <div key={index} className="mb-3">
                              <CardBodyCustom>
                                <ConsentStyledForm className="row">
                                  <TemplateValidateForm key={index} item={item} />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center"
                                    }}
                                  ></div>
                                </ConsentStyledForm>
                              </CardBodyCustom>
                            </div>
                          );
                        }
                      })}
                    </Card>
                  );
                })}
                <div className="d-grid mt-2" style={{ marginTop: "-100%" }}>
                  <Button
                    className="text-center text-uppercase mt-2"
                    type="button"
                    color="primary"
                    onClick={async () => {
                      await mutate(
                        {
                          business: data?.business,
                          country: data?.country,
                          draftId: data?.id,
                          token: user.token
                        },
                        {
                          onSuccess: async () => {
                            setIsOpen(false);
                          }
                        }
                      );
                    }}
                  >
                    Aprobar Templates
                  </Button>
                  <Button
                    className="text-center text-uppercase mt-2"
                    color="bordered"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    Cerrar
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        }
        components={{
          Header: () => (
            <>
              <h4>{title}</h4>
            </>
          ),
          Footer: () => <></>
        }}
      />
    </>
  );
};

export default ListTemplatesModal;
