import { UseQueryResult, useQuery } from "react-query";
import { AuditPropertyWithRelations, ResponseGetAuditPropertyById, useQueryApiError } from "../types";
import { useUser } from "./useUser";
import { getAuditPropertyByIdService } from "../services/getAuditPropertyById";

type useQueryGetAuditPropertyByIdHookType = {
  auditPropertyByIdUseQuery: UseQueryResult<ResponseGetAuditPropertyById, useQueryApiError>;
  dataFetched?: AuditPropertyWithRelations;
  isLoading: boolean;
  isError: boolean;
  error: useQueryApiError | null;
};

type Params = {
  propertyId: string;
};

export const useAuditPropertyById = ({ propertyId }: Params): useQueryGetAuditPropertyByIdHookType => {
  const { user } = useUser();

  const auditPropertyByIdUseQuery = useQuery<ResponseGetAuditPropertyById, useQueryApiError>(
    ["getAuditPropertyById", propertyId],
    async () => {
      const response = await getAuditPropertyByIdService({
        propertyId,
        token: user.token
      });

      return response;
    },
    { cacheTime: 5000 }
  );

  return {
    auditPropertyByIdUseQuery,
    dataFetched: auditPropertyByIdUseQuery.data?.data,
    isError: auditPropertyByIdUseQuery.isError,
    isLoading: auditPropertyByIdUseQuery.isLoading,
    error: auditPropertyByIdUseQuery.error
  };
};
