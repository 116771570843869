import styled, { css } from "styled-components";

type ListFormStyleProps = {
  size?: number;
};

export const ListForm = styled.div`
  ${({ size = 0 }: ListFormStyleProps) => {
    if (size > 8) {
      return css`
        width: 100%;
        height: 380px;
        overflow-x: hidden;
        overflow-y: auto;
      `;
    }

    return css``;
  }}
`;
