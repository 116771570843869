import React, { ReactNode } from "react";
import { PermissionType, UserContextType, UserType } from "../types";

const UserContext = React.createContext({} as UserContextType);

interface Props {
  children: ReactNode | ReactNode[];
}

const INITIAL_USER_STATE = {
  token: "",
  email: "",
  name: ""
};

export function UserContextProvider({ children }: Props): React.ReactElement {
  const [user, setUser] = React.useState<UserType>(INITIAL_USER_STATE);
  const [tenantKey, setTenantKey] = React.useState<string>("");
  const [permission, setPermission] = React.useState<PermissionType>({});

  return (
    <UserContext.Provider value={{ user, setUser, setTenantKey, tenantKey, setPermission, permission }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
