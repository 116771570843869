import React, { useState } from "react";

interface CollapseProps {
  title: string;
}

const Collapse: React.FC<CollapseProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0">
          <button
            className="btn btn-link"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            aria-expanded={isOpen ? "true" : "false"}
          >
            {title}
          </button>
        </h5>
      </div>

      <div className={`collapse ${isOpen ? "show" : ""}`}>
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default Collapse;
