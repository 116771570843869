import axiosInstance from "../config/axiosIntance";
import { GetDraftsInProgressResult } from "../types";

type Params = {
  country: string;
  business: string;
  token: string;
};

export const getTemplatesInDraftService = async ({
  business = "FAL",
  country = "CL",
  token
}: Params): Promise<GetDraftsInProgressResult> => {
  const dataFetched = await axiosInstance.get<GetDraftsInProgressResult>(`/consent-config/draft/inProgress`, {
    headers: {
      Authorization: `Bearer ${token}`,
      country,
      business
    }
  });

  return dataFetched.data;
};
