import { TableColumn } from "react-data-table-component";
import * as XLSX from "xlsx";
import { DocumentAndAccountItemType } from "../../types";
import { parseDate } from "../../utils/parseDate";

export const exportToAuditDataToExcel = (data: DocumentAndAccountItemType[]): void => {
  const fileName = "audit_history.xlsx";
  const dataToExport = data.map((item) => ({
    "#": item.propertyId,
    País: item.country,
    Negocio: item.business,
    Servicio: item.serviceUsed,
    Acción: item.action,
    Motivo: item.reason,
    Usuario: item.userName,
    Documento: item.documentNumber,
    Fecha: parseDate(item.createdAt, "dd/MM/yyyy")
  }));

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Audit History");
  XLSX.writeFile(wb, fileName);
};

export const baseColumnsAuditTable: TableColumn<DocumentAndAccountItemType>[] = [
  {
    name: "#",
    selector: (row: DocumentAndAccountItemType) => row.propertyId,
    width: "50px",
    sortable: true
  },
  {
    name: "País",
    selector: (row: DocumentAndAccountItemType) => row.country,
    width: "75px",
    sortable: true
  },
  {
    name: "Negocio",
    selector: (row: DocumentAndAccountItemType) => row.business,
    width: "80px",
    sortable: true
  },
  {
    name: "Servicio",
    selector: (row: DocumentAndAccountItemType) => row.serviceUsed,
    width: "210px",
    sortable: true
  },
  {
    name: "Acción",
    selector: (row: DocumentAndAccountItemType) => row.action,
    sortable: true
  },
  {
    name: "Motivo",
    selector: (row: DocumentAndAccountItemType) => row.reason,
    width: "150px",
    sortable: true
  },
  {
    name: "Usuario",
    selector: (row: DocumentAndAccountItemType) => row.userName,
    width: "250px",
    wrap: true,
    sortable: true
  },
  {
    name: "Documento",
    selector: (row: DocumentAndAccountItemType) => row.documentNumber,
    width: "150px",
    wrap: true,
    sortable: true
  },
  {
    name: "Fecha",
    selector: (row: DocumentAndAccountItemType) => parseDate(row.createdAt, "dd/MM/yyyy"),
    sortable: true,
    wrap: true
  }
];
