import { Card, CardBody, Tab } from "@jarvis-catalyst/ui-components";
import React from "react";
import { ConsentResponse } from "../../types";
import RelatedConsents from "./RelatedConsents";
import PrimaryConsentTab from "./PrimaryConsentTab";
import ErrorComponent from "../Error";

type ConsentTabProps = {
  data?: ConsentResponse[];
};

const ConsentTab = ({ data }: ConsentTabProps): JSX.Element => {
  return (
    <div>
      {data?.map((consent) => (
        <Card className="shadow" key={consent.consentId}>
          <CardBody>
            <div className="mb-3 mt-md-4">
              <Tab
                pages={[
                  {
                    title: "Consent",
                    key: "template",
                    body: <PrimaryConsentTab consent={consent} />
                  },
                  {
                    title: "Related Consents",
                    key: `related-consents-${consent.consentId}`,
                    body: <RelatedConsents relatedConsents={consent.relatedConsents} />
                  }
                ]}
              />
            </div>
          </CardBody>
        </Card>
      ))}
      {!data?.length && <ErrorComponent isError={404} title={"Consents not found"} />}
    </div>
  );
};

export default ConsentTab;
