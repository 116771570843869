import { UseQueryResult, useQuery } from "react-query";
import { GetDraftsInProgressResult, useQueryApiError } from "../types";
import { getTemplatesInDraftService } from "../services/getTemplatesInDraftService";
import { useUser } from "./useUser";

const keyCache = `templatesDraft`;

type TemplatesDraftHookType = {
  templatesInDraftUseQuery: UseQueryResult<GetDraftsInProgressResult, useQueryApiError>;
};

export const useTemplatesInDraftList = (): TemplatesDraftHookType => {
  const { user } = useUser();
  const templatesInDraftUseQuery = useQuery<GetDraftsInProgressResult, useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getTemplatesInDraftService({
        token: user.token,
        country: "CL",
        business: "FAL"
      });
      return response;
    },
    { cacheTime: 0, retry: 0 }
  );

  return {
    templatesInDraftUseQuery
  };
};
