import { ResponseUnlockUserService, UserSelectedLockBlock } from "../types";
import axiosInstance from "../config/axiosIntance";

type LockUnlockServiceParams = {
  users: UserSelectedLockBlock[];
  serviceProviderName: string;
  operation: string;
  token: string;
};

export const clientLockUnlockService = async ({
  users,
  operation,
  token
}: LockUnlockServiceParams): Promise<ResponseUnlockUserService> => {
  const responseFetched = await axiosInstance.patch(
    `/users`,
    {
      users,
      operation
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  return responseFetched.data;
};
