import styled from "styled-components";

const PRIMARY_COLOR_HEX = "#4a1eeb";
const PRIMARY_COLOR_HOVER_RGBA = "rgba(107, 124, 255, 0.2)";

export const ButtonBackCustom = styled.button`
  width: 75%;
  background-color: transparent;
  border: 2px solid ${PRIMARY_COLOR_HEX};
  border-radius: 6px;
  color: ${PRIMARY_COLOR_HEX};
  padding: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow 0.25s cubic-bezier(0.75, 0.02, 0.58, 1);

  &:hover {
    background-color: ${PRIMARY_COLOR_HOVER_RGBA};
    border: 2px solid ${PRIMARY_COLOR_HEX};
    border-radius: 6px;
    color: ${PRIMARY_COLOR_HEX};
  }
`;

export const DeleteButtonCustom = styled.button`
  width: 100%;
  background-color: #d33;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #b22;
  }
`;

export const ButtonClose = styled.button`
  width: 75%;
  background-color: ${PRIMARY_COLOR_HEX};
  color: #fff;
  border-radius: 6px;
  padding: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow 0.25s cubic-bezier(0.75, 0.02, 0.58, 1);

  &:hover {
    background-color: ${PRIMARY_COLOR_HOVER_RGBA};
    border: 2px solid ${PRIMARY_COLOR_HEX};
    border-radius: 6px;
    color: ${PRIMARY_COLOR_HEX};
  }
`;
