import React, { ReactElement } from "react";
import { formatDate } from "./datepicker";
import {
  Button,
  IconDelete
} from "@jarvis-catalyst/ui-components";

type ClearFiltersProps = {
  handleOnDateChange: (filter: { startDate: string, endDate: string, action: string, page: number }) => void;
}

const ClearFilters = ({ handleOnDateChange }: ClearFiltersProps): ReactElement => {
  const handleOnClearFilter = () => {
    handleOnDateChange({
      startDate: formatDate(new Date(), 3),
      endDate: formatDate(new Date()),
      action: "ALL",
      page: 1,
    })
    
  }
  return (
    <Button className="clear-button" color="ghost" onClick={() => handleOnClearFilter()}>
      <IconDelete />
    </Button>
  )
}

export default ClearFilters;