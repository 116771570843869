import React from "react";
import { Email } from "../../types";
import { Text } from "@jarvis-catalyst/ui-components";

type Props = {
  emails?: Email[];
};

const EmailsTab = ({ emails }: Props): JSX.Element => {
  return (
    <>
      {emails?.map((email) => (
        <div key={email.id}>
          <div className="row">
            <div className="col">
              <Text className="">ID</Text>
              <input type="text" className="form-control" disabled value={email.id} />
            </div>

            <div className="col">
              <Text className="">EmailType</Text>
              <input type="text" className="form-control" disabled value={email.emailType} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Text className="">Email Description</Text>
              <input type="text" className="form-control" disabled value={email.emailTypeDescription} />
            </div>

            <div className="col">
              <Text className="">Email</Text>
              <input type="text" className="form-control" disabled value={email.email} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Text className="">Verified Email</Text>
              <input type="text" className="form-control" disabled value={String(email.verifiedEmail)} />
            </div>

            <div className="col">
              <Text className="">Preferred Email</Text>
              <input type="text" className="form-control" disabled value={String(email.preferredEmail)} />
            </div>
          </div>
          <hr />
        </div>
      ))}
    </>
  );
};

export default EmailsTab;
