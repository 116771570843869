import { ResponseLockUserService, UserType } from "../types";
import axiosInstance from "../config/axiosIntance";

type LockServiceParams = {
  userName?: string;
  serviceProvider: string;
  currentUser: UserType;
};

export const lockUserService = async ({
  userName,
  serviceProvider,
  currentUser
}: LockServiceParams): Promise<ResponseLockUserService> => {
  const responseFetched = await axiosInstance.post(
    `/account/lockUser`,
    {
      userName,
      serviceProvider
    },
    {
      headers: {
        Authorization: `Bearer ${currentUser.token}`
      }
    }
  );

  const { data } = responseFetched.data;

  return data;
};
