import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, Col, Input, Row, Switch, Text } from "@jarvis-catalyst/ui-components";
import ConsentContext from "../../context/ConsentContext";
import { useServiceProviders } from "../../hooks/useServiceProviders";
import {
  countriesOptionsList,
  getListBusinessAliases,
  purposeOptionsList,
  invertedBusinessMap
} from "../../utils/listOptions";
import { usePurposeList } from "../../hooks/usePurposeList";
import LoadingComponent from "../../components/Loading";
import { useTemplateByPurpose } from "../../hooks/useTemplateByPurpose";
import { useUser } from "../../hooks/useUser";
import { consentCheckBox } from "../../config/data/consentCheckBox";
import { useForm } from "react-hook-form";
import { getTemplateByPurpose } from "../../services/getTemplateByPurpose";
import { filterTemplatesByItemOrder } from "../../utils/filterTemplates";
import { getLegalTextServiceByTemplateId } from "../../services/getLegalTextService";
import Swal from "sweetalert2";
import { useConsentConfig } from "../../hooks/useDraft";
import { createConsentOption } from "../../config/data/consentOptions";
import { validateIfHasPermissionToAccessView } from "../../utils/filterPermission";

type Inputs = {
  country: string;
  business: string;
  purpose: string;
  privPolicy: boolean;
  loyaltyRule: boolean;
};

const SearchConsent = (): ReactElement => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { searchConsentForm, setSearchConsentForm, consentChildren, setConsentChildren } =
    useContext(ConsentContext);
  const { useTemplateQuery, enabledCreate, enabledEdit } = useTemplateByPurpose();
  const { purposeListUseQuery } = usePurposeList();
  const { serviceProviderUseQuery } = useServiceProviders();

  const { mutate, isLoading: loadingCheckTemplates } = useTemplateQuery;
  const { isLoading: loadingPurpose } = purposeListUseQuery;

  const [createConsent] = useState(createConsentOption);
  const { permission } = useUser();

  const hasValidPermission = createConsent.some(({ validPermissions }) => {
    return validateIfHasPermissionToAccessView({
      validPermissionsView: validPermissions,
      userPermission: permission,
      action: "create"
    });
  });

  useEffect(() => {
    setConsentChildren([]);
  }, []);

  const {
    formState: { errors },
    getValues,
    register,
    trigger,
    setValue
  } = useForm<Inputs>({
    defaultValues: {
      business: "",
      country: "",
      purpose: "",
      loyaltyRule: false,
      privPolicy: false
    }
  });

  const { consentConfigQuery, setFetched: setFetcheConsentConfig } = useConsentConfig({
    business: getValues("business"),
    country: getValues("country"),
    purpose: getValues("purpose"),
    token: user?.token
  });

  const handleClickSearchConsent = async () => {
    const result = await trigger();

    if (result) {
      setFetcheConsentConfig(true);
      Swal.showLoading();
      const dataConsentConfig = await consentConfigQuery.refetch();
      if (dataConsentConfig?.data?.data.isPurposeInDraft) {
        Swal.fire({
          title: "Advertencia",
          text: "El propósito tiene templates en borrador, favor apruebe los templates o contáctese con el administrador.",
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return;
      }
      await mutate({
        businessUnit: getValues("business"),
        country: getValues("country"),
        purpose: getValues("purpose"),
        token: user?.token
      });
      Swal.close();
    }
  };

  const handleClickCreateConsent = async () => {
    Swal.fire({
      title: "...",
      html: "Espere por favor...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const getTemplatesCMR = await getTemplateByPurpose({
      businessUnit: "LOY",
      country: getValues("country"),
      purpose: "CMRPuntos",
      token: user?.token
    });

    const childrenToAdd = [...consentChildren];

    const countryBusinessName = getValues("country") + getValues("business");
    const baseName = countryBusinessName.concat("_", getValues("purpose"));

    if (getValues("loyaltyRule")) {
      const loyaltyRule = { ...consentCheckBox.loyaltyRule };
      const getTemplate = await filterTemplatesByItemOrder(getTemplatesCMR.data, 1);

      if (getTemplate) {
        const uniqueName = baseName.concat("_", getTemplate.templateName).toUpperCase();

        loyaltyRule.version = getTemplate.templateVersion;
        loyaltyRule.code = uniqueName;
        loyaltyRule.displayText = getTemplate.displayText;
        loyaltyRule.group = Number(getTemplate.templateGroup);

        const { data } = await getLegalTextServiceByTemplateId({
          businessUnit: getValues("business"),
          country: getValues("country"),
          templateId: getTemplate?.templateId,
          token: user?.token
        });
        loyaltyRule.legalText = data.legalText;
        loyaltyRule.position = getTemplate.itemOrder;

        // * add extradata to differentiate between loyaltyRule and privPolicy
        loyaltyRule.extraData = {
          loyaltyRule: true,
        }

        childrenToAdd.push(loyaltyRule);
      }
    }

    if (getValues("privPolicy")) {
      const privPolicy = { ...consentCheckBox.privPolicy };
      const getTemplate = await filterTemplatesByItemOrder(getTemplatesCMR.data, 2);

      if (getTemplate) {
        const uniqueName = baseName.concat("_", getTemplate.templateName).toUpperCase();

        privPolicy.version = getTemplate.templateVersion;
        privPolicy.code = uniqueName;
        privPolicy.displayText = getTemplate.displayText;
        privPolicy.group = Number(getTemplate.templateGroup);

        const { data } = await getLegalTextServiceByTemplateId({
          businessUnit: getValues("business"),
          country: getValues("country"),
          templateId: getTemplate?.templateId,
          token: user?.token
        });

        privPolicy.position = getTemplate.itemOrder;
        privPolicy.legalText = data.legalText;
        privPolicy.extraData = {
          privPolicy: true,
        }

        childrenToAdd.push(privPolicy);
      }
    }

    setConsentChildren(childrenToAdd);

    await setSearchConsentForm({
      business: getValues("business"),
      country: getValues("country"),
      purpose: getValues("purpose"),
      loyaltyRule: getValues("loyaltyRule"),
      privPolicy: getValues("privPolicy")
    });

    Swal.close();

    await navigate("/consent/create", {
      state: {
        searchConsentForm
      }
    });
  };

  const handleClickUpdateConsent = async () => {
    await setSearchConsentForm({
      business: getValues("business"),
      country: getValues("country"),
      purpose: getValues("purpose"),
      loyaltyRule: getValues("loyaltyRule"),
      privPolicy: getValues("privPolicy")
    });

    await navigate("/consent/update", {
      state: {
        searchConsentForm
      }
    });
  };

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={10} lg={10} xs={10} sm={10}>
          <Card className="shadow">
            <h2 className="fw-bold mb-2 text-uppercase text-center">Buscar Consentimiento</h2>
            <hr />
            <CardBody>
              <div className="row">
                <div className="col">
                  <Text className="">País</Text>
                  {serviceProviderUseQuery.isLoading ? (
                    <>
                      <LoadingComponent height="25px" width="25px" marginTop="0px" />
                    </>
                  ) : enabledCreate || enabledEdit ? (
                    <Input value={getValues("country")} onChange={() => null} disabled />
                  ) : (
                    <select
                      className={`form-select ${errors.country ? "is-invalid" : ""}`}
                      {...register("country", { required: true })}
                    >
                      <option value="">Seleccione País</option>
                      {countriesOptionsList(serviceProviderUseQuery.data).map((item, index) => {
                        return (
                          <option key={index} value={item.label}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>

                <div className="col">
                  <Text className="">Negocio</Text>
                  {serviceProviderUseQuery.isLoading ? (
                    <>
                      <LoadingComponent height="25px" width="25px" marginTop="0px" />
                    </>
                  ) : enabledCreate || enabledEdit ? (
                    <Input
                      value={invertedBusinessMap[getValues("business")] ?? getValues("business")}
                      onChange={() => null}
                      disabled
                    />
                  ) : (
                    <select
                      className={`form-select ${errors.business ? "is-invalid" : ""}`}
                      {...register("business", { required: true })}
                    >
                      <option value="">Seleccione Negocio</option>
                      {getListBusinessAliases(serviceProviderUseQuery.data).map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Text className="">Punto de Captura</Text>
                  {purposeListUseQuery.isLoading ? (
                    <>
                      <LoadingComponent height="25px" width="25px" marginTop="0px" />
                    </>
                  ) : enabledCreate || enabledEdit ? (
                    <Input value={getValues("purpose")} onChange={() => null} disabled />
                  ) : (
                    <>
                      <input
                        type="text"
                        className={`form-select ${errors.purpose ? "is-invalid" : ""}`}
                        {...register("purpose", { required: true })}
                        placeholder="Seleccione un Punto de Captura"
                        list="list-experience"
                      />
                      <datalist id="list-experience">
                        {purposeOptionsList(purposeListUseQuery.data).map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label} - {item.value}
                            </option>
                          );
                        })}
                      </datalist>
                    </>
                  )}
                </div>
                {enabledCreate ? (
                  <div className="col">
                    <div className="row">
                      <Text className="col-sm-8 col-form-label">
                        ¿Desea agregar el Reglamento de CMR Puntos?
                      </Text>
                      <div className="col-sm-2 mt-2">
                        <Switch
                          onChange={(value) => {
                            setValue("loyaltyRule", value, { shouldValidate: true });
                          }}
                          value={getValues("loyaltyRule")}
                          size="sm"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <Text className="col-sm-8 col-form-label">
                        ¿Desea agregar la política de privacidad de CMR Puntos?
                      </Text>
                      <div className="col-sm-2 mt-3">
                        <Switch
                          onChange={(value) => {
                            setValue("privPolicy", value, { shouldValidate: true });
                          }}
                          value={getValues("privPolicy")}
                          size="sm"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col"></div>
                )}
              </div>
              {!loadingPurpose && (
                <div className="d-grid mt-4">
                  {!enabledCreate && !enabledEdit && (
                    <>
                      {loadingCheckTemplates ? (
                        <LoadingComponent height="25px" width="25px" marginTop="0px" />
                      ) : (
                        <Button
                          color="primary"
                          className="text-center"
                          disabled={!hasValidPermission}
                          type="button"
                          onClick={handleClickSearchConsent}
                        >
                          Buscar consentimiento
                        </Button>
                      )}
                    </>
                  )}
                  {enabledCreate && (
                    <Button
                      color="success"
                      className="text-center"
                      type="button"
                      onClick={handleClickCreateConsent}
                    >
                      Crear Consentimiento
                    </Button>
                  )}
                  {enabledEdit && (
                    <>
                      <Button
                        color="success"
                        className="text-center"
                        type="button"
                        onClick={handleClickUpdateConsent}
                      >
                        Buscar Consentimiento
                      </Button>
                    </>
                  )}
                  <Button
                    className="text-center text-uppercase mt-2"
                    color="bordered"
                    type="button"
                    onClick={() => navigate("/consent")}
                  >
                    Volver
                  </Button>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SearchConsent;
