import Swal from "sweetalert2";
import { deleteAccountService } from "../services/deleteAccount";
import { deleteUserService } from "../services/deleteUser";
import { FormType, UserType, useQueryApiError } from "../types";
import { UseMutateFunction, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

type DeleteParams = {
  action: string;
  form: FormType;
  currentUser: UserType;
};

export const handleDelete = async ({ action, form, currentUser }: DeleteParams): Promise<unknown> => {
  const { email, serviceProvider, nroDocumento, tipoDocumento } = form;
  if (action === "deleteAccount") {
    const resultDeleteAccount = await deleteAccountService({
      email,
      serviceProvider,
      nroDocumento,
      tipoDocumento,
      currentUser
    });
    return resultDeleteAccount;
  }

  if (action === "deleteUser") {
    return await deleteUserService({
      email,
      serviceProvider,
      nroDocumento,
      tipoDocumento,
      currentUser
    });
  }
  return;
};

type DeleteHookType = {
  mutate: UseMutateFunction<unknown, useQueryApiError, any, () => void>;
  isLoadingAction: boolean;
  isErrorAction: boolean;
  ErrorAction: useQueryApiError | null;
};

export const useDelete = (): DeleteHookType => {
  const navigate = useNavigate();

  const {
    mutate,
    isLoading: isLoadingAction,
    isError: isErrorAction,
    error: ErrorAction
  } = useMutation<unknown, any, any, () => unknown>(handleDelete, {
    onSuccess: () => {
      Swal.fire({
        //position: "top",
        icon: "success",
        title: "Has been deleted.",
        text: "Delete Success",
        didClose() {
          navigate("/");
        }
      });
    },
    onError: (error) => {
      const resultError = error.response.data.data;

      Swal.fire({
        //position: "top",
        icon: "error",
        title: "Error",
        text: resultError.message
      });
      return resultError;
    }
  });
  return { mutate, isLoadingAction, isErrorAction, ErrorAction };
};
