import { Text } from "@jarvis-catalyst/ui-components";
import React from "react";
import { Template } from "../../types";

type Props = {
  template: Template;
};

const TemplateTab = ({ template }: Props): JSX.Element => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <Text className="">Business Short Name</Text>
          <input type="text" className="form-control" disabled value={template.businessShortName} />
        </div>
        <div className="col">
          <Text className="">Country Code</Text>
          <input type="text" className="form-control" disabled value={template.countryCode} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Display Text</Text>
          <input type="text" className="form-control" disabled value={template.displayText} />
        </div>
        <div className="col">
          <Text className="">Is Mandatory</Text>
          <input type="text" className="form-control" disabled value={template.isMandatory} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Template Atomic</Text>
          <input type="text" className="form-control" disabled value={template.templateAtomic} />
        </div>
        <div className="col">
          <Text className="">Template ID</Text>
          <input type="text" className="form-control" disabled value={template.templateId} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Template Name</Text>
          <input type="text" className="form-control" disabled value={template.templateName} />
        </div>
        <div className="col">
          <Text className="">Template Type</Text>
          <input type="text" className="form-control" disabled value={template.templateType} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text className="">Validity Time Consent</Text>
          <input type="text" className="form-control" disabled value={template.validityTimeConsent} />
        </div>
        <div className="col">
          <Text className="">Template Version</Text>
          <input type="text" className="form-control" disabled value={template.templateVersion} />
        </div>
      </div>
    </div>
  );
};

export default TemplateTab;
