import React, { ReactElement } from "react";
import DeleteForm from "../../../components/Form/deleteForm";

const DeleteUser = (): ReactElement => {
  return (
    <>
      <DeleteForm action="deleteUser" title="Delete User" />
    </>
  );
};

export default DeleteUser;
