import React from "react";
import { Alert } from "@jarvis-catalyst/ui-components";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface NotifyProps {
  type: "success" | "danger" | "warning";
  message: string;
}
const StyledContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

const showNotification = ({ type, message }: NotifyProps): void => {
  const container = document.createElement('div');

  document.body.insertBefore(container, document.body.firstChild);

  ReactDOM.render(
    (
      <StyledContainer>
        <Alert color={type}>
          {message}
        </Alert>
      </StyledContainer>
    ),
    container
  );

  setTimeout(() => {
    document.body.removeChild(container);
  }, 5000);
};

export default showNotification;