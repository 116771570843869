import React from "react";
import { Address } from "../../types";
import { Text } from "@jarvis-catalyst/ui-components";

type Props = {
  addresses?: Address[];
};

const AddressesTab = ({ addresses }: Props): JSX.Element => {
  return (
    <>
      {addresses?.map((address) => (
        <div key={address.id}>
          <div className="row">
            <div className="col">
              <Text className="">ID</Text>
              <input type="text" className="form-control" disabled value={address.id} />
            </div>

            <div className="col">
              <Text className="">Address Type</Text>
              <input type="text" className="form-control" disabled value={address.addressType} />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Text className="">Address Type Description</Text>
              <input type="text" className="form-control" disabled value={address.addressTypeDescription} />
            </div>

            <div className="col">
              <Text className="">Country Code</Text>
              <input type="text" className="form-control" disabled value={address.countryCode} />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Text className="">Street</Text>
              <input type="text" className="form-control" disabled value={address.street} />
            </div>

            <div className="col">
              <Text className="">Number Street</Text>
              <input type="text" className="form-control" disabled value={address.numberStreet} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Text className="">City</Text>
              <input type="text" className="form-control" disabled value={address.city} />
            </div>

            <div className="col">
              <Text className="">Urbanization</Text>
              <input type="text" className="form-control" disabled value={address.urbanization} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Text className="">Floor</Text>
              <input type="text" className="form-control" disabled value={address.floor} />
            </div>

            <div className="col">
              <Text className="">Apartment</Text>
              <input type="text" className="form-control" disabled value={address.apartment} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Text className="">Office</Text>
              <input type="text" className="form-control" disabled value={address.office} />
            </div>

            <div className="col">
              <Text className="">Zip Code</Text>
              <input type="text" className="form-control" disabled value={address.zipCode} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Text className="">Verified Address</Text>
              <input type="text" className="form-control" disabled value={String(address.verifiedAddress)} />
            </div>

            <div className="col">
              <Text className="">Preferred Address</Text>
              <input type="text" className="form-control" disabled value={String(address.preferredAddress)} />
            </div>
          </div>
          <hr />
        </div>
      ))}
    </>
  );
};

export default AddressesTab;
