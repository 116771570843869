import { ErrorResult, NoResultFound } from "@jarvis-catalyst/ui-components";
import React from "react";
import { ButtonBackCustom } from "../../styled-components/Buttons";
import { useNavigate } from "react-router-dom";

type Props = {
  isError: number;
  title: string;
  redirectTo?: string;
  text?: string;
};

const checkErrorNumer = (isError: number, title: string, text: string = "Ups."): JSX.Element => {
  if (isError == 404) {
    return <NoResultFound text={text} title={title} />;
  }
  return <ErrorResult text={text} title={title} />;
};

const ErrorComponent = ({ isError, title, redirectTo, text }: Props): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div>
      {checkErrorNumer(isError, title, text)}
      {redirectTo && (
        <div
          style={{
            textAlign: "center"
          }}
        >
          <div className="mt-2 mb-2">
            <ButtonBackCustom onClick={() => navigate(redirectTo ?? "/validateConsent")}>
              Volver
            </ButtonBackCustom>
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorComponent;
