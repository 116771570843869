import React from "react";
import ClientUnlockForm from "../../../components/Form/clientUnlockForm";

const ClientUnlock = (): React.ReactElement => {
  return (
    <div>
      <ClientUnlockForm title="Bloqueo y Desbloqueo de Cuentas" />
    </div>
  );
};

export default ClientUnlock;
