import Swal from "sweetalert2";
import { FormType, UserType, useQueryApiError } from "../types";
import { UseMutateFunction, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { unlockUserService } from "../services/unlockUser";
import { hasMatchFIF } from "../utils/sbifMatcher";

type UnlockParams = {
  action: string;
  form: FormType;
  currentUser: UserType;
};

export const handleUnlock = async ({ action, form, currentUser }: UnlockParams): Promise<unknown> => {
  const { email, serviceProvider, nroDocumento } = form;
  if (action === "unlockPassword") {
    const resultDeleteAccount = await unlockUserService({
      userName: hasMatchFIF(serviceProvider) ? nroDocumento : email,
      serviceProvider,
      currentUser
    });
    return resultDeleteAccount;
  }

  return;
};

type UnlockHookType = {
  mutate: UseMutateFunction<unknown, useQueryApiError, any, () => void>;
  isLoadingAction: boolean;
  isErrorAction: boolean;
  ErrorAction: useQueryApiError | null;
};

export const useUnlock = (): UnlockHookType => {
  const navigate = useNavigate();

  const {
    mutate,
    isLoading: isLoadingAction,
    isError: isErrorAction,
    error: ErrorAction
  } = useMutation<unknown, any, any, () => unknown>(handleUnlock, {
    onSuccess: () => {
      Swal.fire({
        //position: "top",
        icon: "success",
        title: "Has been unlocked.",
        text: "Unlock Success",
        didClose() {
          navigate("/");
        }
      });
    },
    onError: (error) => {
      const resultError = error.response.data.data;

      Swal.fire({
        //position: "top",
        icon: "error",
        title: "Error",
        text: resultError.message
      });
      return resultError;
    }
  });
  return { mutate, isLoadingAction, isErrorAction, ErrorAction };
};
