import { Modal } from "@jarvis-catalyst/ui-components";
import React, { ReactElement, useState } from "react";
import { Row } from "../../styled-components/TableRows";

const BackendHealthCheck = (): ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div>
      <table className="table table-bordered table-hover">
        <thead>
          <tr className="text-uppercase">
            <th scope="col">#</th>
            <th scope="col">API</th>
            <th scope="col">Version</th>
            <th scope="col">Develop</th>
            <th scope="col">Test1</th>
            <th scope="col">Test2</th>
            <th scope="col">UAT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>/customer-account</td>
            <Row className="table-danger fw-bold text-center" onClick={() => setShowModal(true)}>
              1.0.0
            </Row>
            <Row className="table-success fw-bold text-center">1.0.0</Row>
            <Row className="table-success fw-bold text-center">1.0.0</Row>
            <Row className="table-success fw-bold text-center">1.0.0</Row>
            <Row className="table-success fw-bold text-center">1.0.1</Row>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>/consents</td>
            <Row className="table-danger fw-bold text-center">1.0.0</Row>
            <Row className="table-danger fw-bold text-center">1.0.0</Row>
            <Row className="table-success fw-bold text-center">1.0.0</Row>
            <Row className="table-success fw-bold text-center">1.0.0</Row>
            <Row className="table-danger fw-bold text-center">1.0.1</Row>
          </tr>
        </tbody>
      </table>

      {showModal && (
        <Modal isOpen={showModal} size="md" onBackgroundClose={() => setShowModal(false)}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <hr />
            <div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BackendHealthCheck;
