import React, { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";

import Layout from "./components/Layout";
import { ApplicationLoaded, EventFactory } from "./jarvisEvents/eventsFactory";
import MainRoutes from "./routes";

function App(): ReactElement {
  return (
    <BrowserRouter>
      <Layout>
        <EventFactory />
        <ApplicationLoaded />
        <MainRoutes />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
