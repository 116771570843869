import Swal from "sweetalert2";
import { UseMutateFunction, UseMutationResult, useMutation } from "react-query";
import { useQueryApiError, ConsentChildrenType, ResponseCreateTemplateService } from "../types";
import { createTemplateService } from "../services/createTemplateService";
import { useNavigate } from "react-router";

type MutationParams = {
  business: string;
  country: string;
  purpose: string;
  templates: ConsentChildrenType[];
  token: string;
};

type CreateTemplateHookType = {
  useCreateTemplateQuery: UseMutationResult<
    ResponseCreateTemplateService,
    useQueryApiError,
    MutationParams,
    unknown
  >;
  loading: boolean;
  error: boolean;
  mutate: UseMutateFunction<ResponseCreateTemplateService, useQueryApiError, MutationParams, unknown>;
};

export const useCreateTemplate = (): CreateTemplateHookType => {
  const navigate = useNavigate();
  const useCreateTemplateQuery = useMutation<
    ResponseCreateTemplateService,
    useQueryApiError,
    MutationParams,
    unknown
  >({
    mutationFn: async ({ business, country, purpose, templates, token }: MutationParams) => {
      return await createTemplateService({ business, country, purpose, templates, token });
    },
    onMutate: () => {
      Swal.fire({
        title: "...",
        html: "Please wait, we are creating the template.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onSuccess: () => {
      Swal.fire({
        title: "Success!",
        text: "The templates was created successfully.",
        icon: "success",
        width: "650px",
        confirmButtonText: "Ok",
        didClose() {
          navigate("/consent");
        }
      });
    },
    onError: (error) => {
      Swal.close();
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "Ok"
      });
    }
  });

  return {
    useCreateTemplateQuery,
    loading: useCreateTemplateQuery.isLoading,
    error: useCreateTemplateQuery.isError,
    mutate: useCreateTemplateQuery.mutate
  };
};
