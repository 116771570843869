import { whiteListPurpose } from "../config/constants";
import { PermissionType } from "../types";

export const validateAction = (
  permissions: PermissionType,
  permissionAccess: string,
  action: string
): boolean => {
  const listPermissions = permissions[permissionAccess];
  const hasPermission = listPermissions?.some((permission) => permission === action);

  return hasPermission ?? false;
};

export const validatePermissionWithEmail = (email: string): boolean => {
  if (whiteListPurpose.includes(email)) {
    return true;
  }

  return false;
};

type ValidateIfHasPermissionToAccessViewType = {
  validPermissionsView: string[];
  userPermission: PermissionType;
  action: string;
};

export const validateIfHasPermissionToAccessView = ({
  validPermissionsView,
  userPermission,
  action
}: ValidateIfHasPermissionToAccessViewType): boolean => {
  const hasValidPermission = validPermissionsView.some((validPermission) => {
    const hasPermission = validateAction(userPermission, validPermission, action);
    return hasPermission;
  });
  return hasValidPermission;
};
