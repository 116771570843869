import axiosInstance from "../config/axiosIntance";
import { Properties } from "../types";

export type ResponseGetAllAuditsService = {
  data: Properties[];
};

type AuditParams = {
  token?: string;
  page?: number;
  startDate?: string | Date;
  endDate?: string | Date;
  action: string ;
};

const initStartDate = new Date();
initStartDate.setMonth(initStartDate.getMonth() - 3);
const initEndDate = new Date();

export const getAllAuditsService = async ({
  token,
  page= 1,
  startDate= initStartDate.toString(),
  endDate= initEndDate.toString(),
  action= 'ALL'
}: AuditParams): Promise<ResponseGetAllAuditsService> => {

  const dataFetched = await axiosInstance.get("/audits/", {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      page,
      startDate,
      endDate,
      action
    }
  });
  return dataFetched.data;
};
