import React from "react";
import { Route } from "react-router-dom";
import { ConsentContextProvider } from "../context/ConsentContext";
import ConsentOption from "../pages/ConsentOption";
import CreatePurpose from "../pages/Purpose/Create";
import PrivateRoutes from "./privateRoutes";
import SearchConsent from "../pages/Consent";
import CreateConsent from "../pages/Consent/Create/create";
import Draft from "../pages/Draft";
import UpdateConsentQuery from "../pages/Consent/Update/updateConsentQuery";

const BASE_PATH = "/consent";

const ConsentRoutes = (): React.ReactElement => {
  return (
    <Route
      element={
        <ConsentContextProvider>
          <PrivateRoutes />
        </ConsentContextProvider>
      }
    >
      <Route path={`${BASE_PATH}`}>
        <Route index element={<ConsentOption />} />
        <Route path={`${BASE_PATH}/create-purpose`} element={<CreatePurpose />} />
        <Route path={`${BASE_PATH}/search-consent`} element={<SearchConsent />} />
        <Route path={`${BASE_PATH}/create`} element={<CreateConsent />} />
        <Route path={`${BASE_PATH}/update`} element={<UpdateConsentQuery />} />
        <Route path={`${BASE_PATH}/draft`} element={<Draft />} />
      </Route>
    </Route>
  );
};

export default ConsentRoutes;
