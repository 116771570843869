import axiosInstance from "../config/axiosIntance";
import { BFF_BASE_URL } from "../config/constants";
import { ResponseCreatePurposeService, UserType } from "../types";

type PurposeParams = {
  name: string;
  description: string;
  currentUser: UserType;
};

export const createPurposeService = async ({
  description,
  name,
  currentUser
}: PurposeParams): Promise<ResponseCreatePurposeService> => {
  const responseFetched = await axiosInstance.post(
    `${BFF_BASE_URL}/ciam-tools-bff/consent/purpose`,
    {
      name,
      description
    },
    {
      headers: {
        Authorization: `Bearer ${currentUser.token}`
      }
    }
  );

  const { data } = responseFetched.data;
  return data;
};
