import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Icon, Row } from "@jarvis-catalyst/ui-components";
import React, { ReactElement, useState } from "react";
import { ButtonBackCustom } from "../../styled-components/Buttons";
import { useNavigate } from "react-router";
import LoadingComponent from "../../components/Loading";
import { useDocumentAndAccountHistory } from "../../hooks/useDocumentAndAccountHistory";
import ErrorComponent from "../../components/Error";
import PropertyDetailModal from "../../components/Modal/PropertyDetailModal";
import { blocklistOptions } from "../../config/data/userOptions";
import { validateIfHasPermissionToAccessView } from "../../utils/filterPermission";
import { useUser } from "../../hooks/useUser";
import { DocumentAndAccountItemType } from "../../types";
import { paginationComponentOptions, paginationRowsPerPageOptions } from "../../config/constants";
import { baseColumnsAuditTable, exportToAuditDataToExcel } from "./auditTable";

const AuditDocumentAndAccount = (): ReactElement => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const { dataFetched, isLoading, isError, error, handleChange, dataFiltered } =
    useDocumentAndAccountHistory();

  const [blocklist] = useState(blocklistOptions);
  const { permission } = useUser();

  const hasValidPermission = blocklist.some(({ validPermissions }) => {
    return validateIfHasPermissionToAccessView({
      validPermissionsView: validPermissions,
      userPermission: permission,
      action: "read"
    });
  });

  const columns: TableColumn<DocumentAndAccountItemType>[] = [
    ...baseColumnsAuditTable,
    {
      name: "",
      cell: (row: DocumentAndAccountItemType) => (
        <Button
          disabled={!hasValidPermission}
          className="btn btn-primary"
          onClick={() => {
            setPropertyId(row.propertyId);
            setOpenModal(true);
          }}
        >
          <Icon name="View" color="white" size={24} />
        </Button>
      )
    }
  ];

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col>
          <div
            style={{
              maxWidth: "150px"
            }}
          >
            <div className="mt-2 mb-2">
              <ButtonBackCustom onClick={() => navigate("/user-management")}>Volver</ButtonBackCustom>
            </div>
          </div>
          <Card className="shadow table-responsive">
            <h2 className="fw-bold mb-2 text-uppercase text-center">Historial de bloqueos & desbloqueos</h2>
            <hr />

            {isLoading ? (
              <LoadingComponent />
            ) : (
              <>
                {isError ? (
                  <>
                    <ErrorComponent
                      isError={error?.statusCode ?? 500}
                      title={error?.message ?? "Ha Ocurrido un error inesperado"}
                    />
                  </>
                ) : (
                  <>
                    {openModal && (
                      <PropertyDetailModal
                        isOpen={openModal}
                        setIsOpen={setOpenModal}
                        title="Detalle de un bloqueo & desbloqueo de cuenta y documento"
                        propertyId={propertyId}
                      />
                    )}
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          exportToAuditDataToExcel(dataFetched);
                        }}
                      >
                        Exportar a Excel
                      </Button>
                    </div>
                    <div className="d-flex justify-content-end mt-2 mb-2">
                      <div className="mr-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Buscar..."
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <CardBody>
                      <DataTable
                        columns={columns}
                        data={dataFiltered}
                        pagination
                        paginationPerPage={20}
                        fixedHeaderScrollHeight="600px"
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        fixedHeader
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={{
                          rows: {
                            style: {
                              minHeight: "45px"
                            }
                          },
                          headCells: {
                            style: {
                              paddingLeft: "8px",
                              paddingRight: "8px"
                            }
                          },
                          cells: {
                            style: {
                              paddingLeft: "8px",
                              paddingRight: "8px"
                            }
                          }
                        }}
                      />
                    </CardBody>
                  </>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AuditDocumentAndAccount;
