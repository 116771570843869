import axiosInstance from "../config/axiosIntance";
import { BFF_BASE_URL } from "../config/constants";
import { ConsentChildrenType, ResponseCreateTemplateService } from "../types";

type CreateTemplateParams = {
  country: string;
  business: string;
  purpose: string;
  templates: ConsentChildrenType[];
  token: string;
};

export const createTemplateService = async ({
  country,
  business,
  purpose,
  templates,
  token
}: CreateTemplateParams): Promise<ResponseCreateTemplateService> => {
  const responseFetched = await axiosInstance.post(
    `${BFF_BASE_URL}/ciam-tools-bff/consent/consentConfig`,
    {
      country,
      business,
      purpose,
      templates
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  return responseFetched.data;
};
